import { useMemo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

export function GaugeChartWithNeedleOrp({ needleValue }) {
	ChartJS.register(ArcElement, Tooltip, Legend);

	const defaultData = {
		labels: [
			'-9999',
			'-8999',
			'-7999',
			'-6999',
			'-5999',
			'-4999',
			'-3999',
			'-2999',
			'-1999',
			'-999',
			'0',
			'999',
			'1999',
			'2999',
			'3999',
			'4999',
			'5999',
			'6999',
			'7999',
			'8999',
			'9999',
		],
		datasets: [
			{
				label: 'ORP Value',
				data: new Array(21).fill(100),
				backgroundColor: [
					'#5C6FA6', // Navy Blue
					'#5C7FA6', // Dark Blue
					'#5C8FA6', // Medium Blue
					'#5C9FA6', // Light Blue
					'#5CAFA6', // Lighter Blue
					'#5CBFA6', // Very Light Blue
					'#5CCFA6', // Pale Blue
					'#5CDFA6', // Very Pale Blue
					'#5CEFA6', // Almost White Blue
					'#5CFFA6', // Very Pale Green Blue
					'#F0FFF0', // Almost White
					'#FFCCCC', // Very Pale Red
					'#FF9999', // Pale Red
					'#FF6666', // Light Red
					'#FF3333', // Medium Red
					'#FF0000', // Red
					'#CC0000', // Darker Red
					'#990000', // Even Darker Red
					'#660000', // Very Dark Red
					'#330000', // Almost Black Red
					'#000000', // Black
				],
				borderColor: new Array(21).fill('rgba(0, 0, 0, 1)'),
				borderWidth: 1,
				circumference: 180,
				rotation: 270,
				cutout: '80%',
				needleValue: 0,
			},
		],
	};

	const options = {
		aspectRatio: 1.5,
		layout: {
			padding: 40,
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
		},
	};

	const gaugeNeedle = {
		id: 'gaugeNeedle',
		afterDatasetsDraw(chart, args, options) {
			const { ctx, data } = chart;
			ctx.save();
			const xCenter = chart.getDatasetMeta(0).data[0].x;
			const yCenter = chart.getDatasetMeta(0).data[0].y;
			const outRadius = chart.getDatasetMeta(0).data[0].outerRadius;
			const inRadius = chart.getDatasetMeta(0).data[0].innerRadius;

			const widthSlice = (outRadius - inRadius) / 2;
			const radius = 15;
			const angle = Math.PI / 180;

			const needleValue = data.datasets[0].needleValue;
			const totalRange = 19998; // Total range from -9999 to 9999
			const adjustedNeedleValue = ((needleValue + 9999) / totalRange) * Math.PI;

			ctx.translate(xCenter, yCenter);
			ctx.rotate(adjustedNeedleValue - Math.PI / 2);

			// needle
			ctx.beginPath();
			ctx.strokeStyle = 'black';
			ctx.fillStyle = 'grey';
			ctx.lineWidth = 1;
			ctx.moveTo(0 - radius, 0);
			ctx.lineTo(0, 0 - inRadius - widthSlice);
			ctx.lineTo(0 + radius, 0);
			ctx.closePath();
			ctx.fill();
			ctx.stroke();

			// dot
			ctx.beginPath();
			ctx.arc(0, 0, radius, 0, angle * 360, false);
			ctx.fill();

			ctx.restore();
		},
	};

	const gaugeFlowMeter = {
		id: 'gaugeFlowMeter',
		afterDatasetsDraw(chart, args, plugins) {
			const { ctx, data } = chart;
			ctx.save();
			const needleValue = data.datasets[0].needleValue;
			const xCenter = chart.getDatasetMeta(0).data[0].x;
			const yCenter = chart.getDatasetMeta(0).data[0].y;

			const currentORPValue = needleValue;

			// flowMeter
			ctx.font = 'bold 30px sans-serif';
			ctx.fillStyle = 'black';
			ctx.textAlign = 'center';
			ctx.fillText(`${currentORPValue.toFixed(1)}`, xCenter, yCenter + 45);
		},
	};

	const gaugeLabels = {
		id: 'gaugeLabels',
		afterDatasetsDraw(chart, args, plugins) {
			const { ctx } = chart;
			const xCenter = chart.getDatasetMeta(0).data[0].x;
			const yCenter = chart.getDatasetMeta(0).data[0].y;
			const outRadius = chart.getDatasetMeta(0).data[0].outerRadius;
			const inRadius = chart.getDatasetMeta(0).data[0].innerRadius;
			const widthSlice = (outRadius - inRadius) / 2;

			ctx.save();
			ctx.translate(xCenter, yCenter);
			ctx.font = 'bold 15px sans-serif';
			ctx.fillStyle = 'black';
			ctx.textAlign = 'center';
			ctx.fillText('REDUTOR', 0 - inRadius - widthSlice, 0 + 30);
			ctx.fillText('OXIDANTE', 0 + inRadius + widthSlice, 0 + 30);
			ctx.fillText('NEUTRO', 0, 0 - 175);
			ctx.restore();
		},
	};

	const plugins = [gaugeNeedle, gaugeFlowMeter, gaugeLabels];

	const data = useMemo(() => {
		const newData = { ...defaultData };
		newData.datasets[0].needleValue = parseFloat(needleValue);
		return newData;
	}, [needleValue]);

	return <Doughnut data={data} options={options} plugins={plugins} />;
}
