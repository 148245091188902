import React from "react";

import WaterFlowDataAnimalPanel from "../../panel/water-flow-data-animal-panel";
import WaterTemperatureDataAnimalPanel from "../../panel/water-temperature-data-animal-panel";

import NoDeviceConnectedMessage from "../../messages-panel/no-device-connected-message";
import { waterTempSensor, flowWaterAcc } from "../../../../sensors-type";

export default function WaterLoggingData({
	dataloggersList,
	sensors,
	sensorsType,
}) {
	// Obtem os sensores conectados ao coletor da estação WaterLogging
	let hydrometerSensor, sensorWaterTemp;

	if (dataloggersList.length > 0) {
		dataloggersList.forEach((element) => {
			let getSensors = sensors.filter((s) => element.id === s.coletor);

			getSensors.forEach((s) => {
				let type = sensorsType.filter((el) => s.tipo === el.id);

				if (type.length > 0) {
					s.tipo = type[0].nome;
				}

				if (s.tipo === waterTempSensor) sensorWaterTemp = s;
				else if (s.tipo === flowWaterAcc) hydrometerSensor = s;
			});
		});
	}

	return (
		<div className="dropdown-button-line">
			{dataloggersList.length === 0 ||
			(!hydrometerSensor && !sensorWaterTemp) ? (
				<NoDeviceConnectedMessage />
			) : (
				<div className="show-data-container">
					{hydrometerSensor ? (
						<WaterFlowDataAnimalPanel sensor={hydrometerSensor} />
					) : null}
					{sensorWaterTemp ? (
						<WaterTemperatureDataAnimalPanel
							sensor={sensorWaterTemp}
						/>
					) : null}
				</div>
			)}
		</div>
	);
}
