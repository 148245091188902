import { GaugeChartWithNeedleOrp } from "../../components/charts";
import DataTablePanel from "../../components/panels/data-table-panel/data-table-panel";
import OrpPanel from "../../components/panels/orp-panel";

export default function OrpMode({ data }) {
  return (
    <div className="dropdown-button-line">
      <div className="show-data-container">
        <OrpPanel data={data} />
        {/* <PhAlarm phValue={data[0].ValorSns_4} type="Máximo" data={data} />
        <PhAlarm phValue={data[0].ValorSns_5} type="Mínimo" data={data} /> */}
        <DataTablePanel data={data} type={"Orp"} />
      </div>
    </div>
  );
}
