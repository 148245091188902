import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BiDotsHorizontal } from 'react-icons/bi';
import waterTempIcon from '../../../../assets/icons/water-temperature-icon.png';
import { LineChart1Element } from '../charts';
import SelectDataButton from '../date-selector';

import NoDataAvailableMessage from '../messages-panel/no-data-available-message';

export default function WaterTemperaturePanel({ data }) {
	const [periodo1, setPeriodo1] = useState('');
	const [periodo2, setPeriodo2] = useState('');
	const [waterTempArray, setWaterTempArray] = useState([]);
	const [tempLastData, setTempLastData] = useState(null);

	let filteredData = data;

	if (periodo1 === '') {
		let lastWeek = new Date();
		lastWeek.setDate(lastWeek.getDate() - 5);

		lastWeek = moment(lastWeek).unix();
		lastWeek = moment.unix(lastWeek);

		filteredData = filteredData.filter((item) =>
			moment(moment.unix(item.timestamp)).isSameOrBefore(moment(lastWeek))
		);

		setPeriodo1(new Date(moment(lastWeek).format('YYYY-MM-DDT00:00')));
	}

	let getData = filteredData;

	useEffect(() => {
		let dataArray = [];
		let exportDataAux = [];
		if (getData.length > 0) {
			dataArray = getData.map((d) => {
				exportDataAux.push({
					id: d.id,
					data: moment.unix(d.timestamp).format('DD/MM/YY HH:mm'),
					temperatura_agua: +d.ValorSns_6,
				});

				return {
					timestamp: moment.unix(d.timestamp).format('YYYY-MM-DDTHH:mm:ss'),
					value: +d.ValorSns_6,
				};
			});

			exportDataAux = exportDataAux.map((objeto) => {
				const { id: propriedadeRemovida, ...restoDoObjeto } = objeto;
				return restoDoObjeto;
			});
		}

		setTempLastData(filteredData[0]);
		setWaterTempArray(dataArray);
	}, [filteredData, getData]);

	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">Dados de Temperatura da Água</em>
			</div>
			<div style={{ margin: '10px' }}>
				<div className="panel-items">
					<BiDotsHorizontal />
					<span className="panel-items-title">Último Valor</span>
				</div>
				{tempLastData ? (
					<div className="last-data-container">
						<div className="last-data-content-container">
							<img
								src={waterTempIcon}
								height={'40px'}
								width={'40px'}
								alt=""
								style={{ marginRight: '5px' }}
							/>

							<p className="last-value-text">{+tempLastData.ValorSns_6} ºC</p>
						</div>

						<span style={{ fontSize: '10px' }}>
							Dados atualizados em {moment.unix(tempLastData.timestamp).format('DD-MM-YY HH:mm')}
						</span>
					</div>
				) : (
					<NoDataAvailableMessage />
				)}

				<div className="panel-items">
					<BiDotsHorizontal />
					<span className="panel-items-title">Gráfico de Temperatura da Água</span>
				</div>

				<SelectDataButton
					periodo1={periodo1}
					setPeriodo1={setPeriodo1}
					periodo2={periodo2}
					setPeriodo2={setPeriodo2}
					hourlyData={true}
				/>
				{waterTempArray.length > 0 ? (
					<div className="chart-container">
						<LineChart1Element
							data={waterTempArray}
							unit={'ºC'}
							color={'#004C99'}
							fileName={'grafico-temp-agua'}
						/>
					</div>
				) : (
					<NoDataAvailableMessage />
				)}
			</div>
		</div>
	);
}
