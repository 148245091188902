import "./styles.css";

export default function SensorLevelStatus({ data }) {
  // Função para obter o texto baseado no valor de ValorSns_3
  const getStatusText = (value) => {
    switch (parseInt(value)) {
      case 0:
        return "Acionado";
      case 1:
        return "Desacionado";
      default:
        return "Desconhecido";
    }
  };

  // Verifica se data[0] existe e tem a propriedade ValorSns_3
  const statusText = data[0] ? getStatusText(data[0].ValorSns_3) : "Desconhecido";

  return (
    <div className="little-panel-container">
      <div className="panel-title-container">
        <em className="panel-title">Status Sensor de Nível</em>
      </div>
      <div className="show-basic-data-container">
        <div className={`status-indicator ${statusText.toLowerCase()}`}></div>
        <h3>{statusText}</h3>
      </div>
    </div>
  );
}
