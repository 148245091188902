import DataTablePanel from "../../components/panels/data-table-panel/data-table-panel";
import PhPanel from "../../components/panels/ph-panel";
import WaterTemperaturePanel from "../../components/panels/water-temperature-panel";
import PhAlarm from "./components/ph-alarm/ph-alarm";

export default function PhMode({ data }) {
  return (
    <div className="dropdown-button-line">
      <div className="show-data-container">
        <PhPanel data={data} />
        {/* <PhAlarm phValue={data[0].ValorSns_4} type="Máximo" data={data} />
        <PhAlarm phValue={data[0].ValorSns_5} type="Mínimo" data={data} /> */}
        <WaterTemperaturePanel data={data} />
        <DataTablePanel data={data} type={"Ph"} />
      </div>
    </div>
  );
}
