import DataTablePanel from "../../components/panels/data-table-panel/data-table-panel";
import SensorLevelConfig from "../../components/sensor-level-config/sensor-level-config";
import SensorLevelStatus from "../../components/sensor-level-status/sensor-level-status";
import SimpleFactor from "../../components/simple-factor/simple-factor";

export default function MultiplicationMode({ data }) {
  return (
    <div className="dropdown-button-line">
      <div className="show-data-container">
        <SimpleFactor
          factorType={"Multiplicação"}
          factorValue={data[0].ValorSns_1}
        />
        <SensorLevelConfig data={data} />
        <SensorLevelStatus data={data} />
        <DataTablePanel data={data} type={"Multiplicador"} />
      </div>
    </div>
  );
}
