import DataTablePanel from "../../components/panels/data-table-panel/data-table-panel";
import SensorLevelConfig from "../../components/sensor-level-config/sensor-level-config";
import SensorLevelStatus from "../../components/sensor-level-status/sensor-level-status";
import OperationFlux from "./components/operation-flux";

export default function ManualMode({ data }) {
  return (
    <div className="dropdown-button-line">
      <div className="show-data-container">
          <OperationFlux data={data}/>
          <SensorLevelConfig data={data} />
          <SensorLevelStatus data={data}/>
          <DataTablePanel data={data} type={"Manual"} />
      </div>
    </div>
  );
}
