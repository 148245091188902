import React from "react";
import "./style.css";

const LinearGauge = ({ value }) => {
  const percentage = (value / 14) * 100;

  return (
    <div className="linear-gauge">
      <div className="gauge-value">{value.toFixed(2)}</div>
      <div className="gauge-scale">
        <div className="gauge-fill" style={{ width: `${percentage}%` }}></div>
        <div className="gauge-marker" style={{ left: `${percentage}%` }}></div>
      </div>
      <div className="gauge-labels">
        <span>Ácido</span>
        <span>Neutro</span>
        <span>Alcalino</span>
      </div>
    </div>
  );
};

export default LinearGauge;
