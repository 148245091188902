import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";

import ExportButton from "../../../data-table-custom/export-button";
import downloadCSV from "../../../../scripts/export-script";

import SelectDataButton from "../../date-selector";
import NoDataAvailableMessage from "../../messages-panel/no-data-available-message";
import ErrorMessage from "../../messages-panel/error-message";

import { BarChart1Element } from "../../charts";
import moment from "moment";

import waterFlowIcon from "../../../../assets/icons/water-flow-icon.png";
import { BiDotsHorizontal } from "react-icons/bi";

import { diffFunction } from "../../../../lib/diff-array";

export default function WaterFlowDataAnimalPanel({ sensor, datalogger }) {
	const { access_token } = AuthService.getCurrentUser();
	// data inicial e final para o intervalo de dados do grafico
	const [periodo1, setPeriodo1] = useState("");
	const [periodo2, setPeriodo2] = useState("");
	// data inicial e final para o intervalo de dados do valor acumulado
	const [periodo1Acc, setPeriodo1Acc] = useState("");
	const [periodo2Acc, setPeriodo2Acc] = useState("");

	const [waterFlowArray, setWaterFlowArray] = useState([]); // Para o Gráfico
	const [waterFlowAccValue, setWaterFlowAccValue] = useState(NaN); // Para o painel de valor acumulado
	const [showChart, setShowChart] = useState(false);
	let api_request, api_request_acc;

	if (periodo1Acc === "") {
		let lastDay = new Date();
		lastDay.setDate(lastDay.getDate() - 1);
		lastDay = moment(lastDay).format("YYYY-MM-DDT03:00");
		api_request = `evento_consulta?tipo_mensagem=1&sensor=${sensor.id_dispositivo}&data:>=${lastDay}`;
		setPeriodo1Acc(new Date(moment(lastDay).format("YYYY-MM-DDT00:00")));
	} else {
		let data1 = moment(periodo1Acc).utc().format("YYYY-MM-DDTHH:mm");

		if (periodo2Acc !== "") {
			let data2 = moment(periodo2Acc).utc().format("YYYY-MM-DDTHH:mm");
			api_request_acc = `evento_consulta?tipo_mensagem=1&sensor=${sensor.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
		} else {
			api_request_acc = `evento_consulta?tipo_mensagem=1&sensor=${sensor.id_dispositivo}&data:>=${data1}`;
		}
	}

	if (periodo1 === "") {
		let lastWeek = new Date();
		lastWeek.setDate(lastWeek.getDate() - 5);
		lastWeek = moment(lastWeek).format("YYYY-MM-DDT03:00");
		api_request = `evento_consulta?tipo_mensagem=1&sensor=${sensor.id_dispositivo}&data:>=${lastWeek}`;
		setPeriodo1(new Date(moment(lastWeek).format("YYYY-MM-DDT00:00")));
	} else {
		let data1 = moment(periodo1).utc().format("YYYY-MM-DDTHH:mm");

		if (periodo2 !== "") {
			let data2 = moment(periodo2).utc().format("YYYY-MM-DDTHH:mm");
			api_request = `evento_consulta?tipo_mensagem=1&sensor=${sensor.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
		} else {
			api_request = `evento_consulta?tipo_mensagem=1&sensor=${sensor.id_dispositivo}&data:>=${data1}`;
		}
	}

	let getDataAcc = useGet(api_request_acc, access_token);
	let getData = useGet(api_request, access_token);
	let getModelo = useGet(
		`modelo_consulta?modelo_id=${sensor.modelo}`,
		access_token
	);

	useEffect(() => {
		let dataAccArray = [];
		let accValue = NaN;
		let dataArray = [];

		if (
			!getDataAcc.state.loading &&
			!getDataAcc.state.error &&
			getDataAcc.state.data.length > 0 &&
			!getData.state.loading &&
			!getData.state.error &&
			getData.state.data.length > 0 &&
			!getModelo.state.loading &&
			!getModelo.state.error &&
			getModelo.state.data.length > 0
		) {
			// Dados para serem usados no painel de valor acumulado
			dataAccArray = getDataAcc.state.data.map((d) => {
				return {
					timestamp: +d.timestamp,
					value: +d.valor_sensor,
				};
			});

			// Dados para serem usados no gráfico e para serem exportados
			let dataArrayAux = getData.state.data.map((d) => {
				return {
					timestamp: +d.timestamp,
					value: +d.valor_sensor,
				};
			});

			if (getModelo.state.data[0].nome.includes("limitado")) {
				dataAccArray = diffFunction(dataAccArray);

				dataArrayAux = diffFunction(dataArrayAux);
			}

			accValue =
				dataAccArray[dataAccArray.length - 1].value -
				dataAccArray[0].value;

			let groupDataArray = dataArrayAux.reduce((acc, d) => {
				let day = moment.unix(d.timestamp / 1000).format("YYYY-MM-DD");

				if (!acc[day]) acc[day] = []; // Inicializa o array se ainda não existir

				acc[day].push(d.value); // Adiciona o valor ao array correspondente à data

				return acc;
			}, {});

			dataArray = Object.keys(groupDataArray).map((day) => {
				let firstValue = groupDataArray[day][0];

				let lastValue =
					groupDataArray[day][groupDataArray[day].length - 1];

				let diff = lastValue - firstValue;

				return { timestamp: day, value: diff.toFixed(2) };
			});
		}

		setWaterFlowAccValue(accValue);
		setWaterFlowArray(dataArray);
	}, [
		getDataAcc.state.loading,
		getDataAcc.state.error,
		getDataAcc.state.data,
		getData.state.loading,
		getData.state.error,
		getData.state.data,
		getModelo.state.loading,
		getModelo.state.error,
		getModelo.state.data,
	]);

	const Export = ({ onExport }) => (
		<ExportButton onExport={onExport} chartPanel={true} />
	);

	return (
		<div>
			<div className="panel-title-container">
				<em className="panel-title">Fluxo de Água: {datalogger.tag}</em>
			</div>
			<div>
				{getDataAcc.state.loading ||
				getData.state.loading ||
				getModelo.state.loading ? (
					<div className="panel-loading">
						<ReactLoading
							type="spinningBubbles"
							color="var(--main-color)"
							width="50px"
							height="50px"
						/>
					</div>
				) : getDataAcc.state.error ||
				  getData.state.error ||
				  getModelo.state.error ? (
					<ErrorMessage />
				) : (
					<div style={{ margin: "10px" }}>
						<div className="panel-items">
							<BiDotsHorizontal />
							<span className="panel-items-title">
								Valor Acumulado
							</span>
						</div>
						<SelectDataButton
							periodo1={periodo1Acc}
							setPeriodo1={setPeriodo1Acc}
							periodo2={periodo2Acc}
							setPeriodo2={setPeriodo2Acc}
							hourlyData={true}
						/>
						<div className="last-data-container">
							<div className="last-data-content-container">
								<img
									src={waterFlowIcon}
									height={"40px"}
									width={"40px"}
									alt="water-flux-icon"
									style={{ marginRight: "5px" }}
								/>

								{!isNaN(waterFlowAccValue) ? (
									<p className="last-value-text">
										{waterFlowAccValue.toFixed(2)} m³
									</p>
								) : (
									<p className="last-value-text">-- m³</p>
								)}
							</div>
						</div>
						<button
							className="more-details-button"
							onClick={() => setShowChart(!showChart)}
						>
							+ Detalhes
						</button>
						{showChart ? (
							<div>
								<div className="panel-items">
									<BiDotsHorizontal />
									<span className="panel-items-title">
										Gráfico do Fluxo de Água
									</span>
								</div>
								<SelectDataButton
									periodo1={periodo1}
									setPeriodo1={setPeriodo1}
									periodo2={periodo2}
									setPeriodo2={setPeriodo2}
									hourlyData={false}
								/>
								{waterFlowArray.length > 0 ? (
									<BarChart1Element
										data={waterFlowArray}
										unit={"m³"}
										color={"#187BCD"}
										fileName={"grafico-fluxo-agua-diaria"}
									/>
								) : (
									<NoDataAvailableMessage />
								)}
								{waterFlowArray.length > 0 ? (
									<div className="panel-items">
										<BiDotsHorizontal />
										<span className="panel-items-title">
											Exportar dados do Gráfico
										</span>
										<div className="right-container">
											<Export
												onExport={() =>
													downloadCSV(
														waterFlowArray,
														`dados-hidrometro-NS${sensor.serie}`
													)
												}
											/>
										</div>
									</div>
								) : null}
							</div>
						) : null}
					</div>
				)}
			</div>
		</div>
	);
}
