import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGet } from "../../reducers/useGet";
import exattaLogo from "../../assets/exattaLogo.png";
import AuthService from "../../services/auth";
import { FiLogOut } from "react-icons/fi";
import {
	MdOutlineDashboard,
	MdOutlineRouter,
	MdOutlineDeviceHub,
	MdOutlineCalculate,
	MdOutlineSettingsRemote,
	MdOutlineArticle,
	MdOutlineWaterDrop,
} from "react-icons/md";
import { FaSeedling, FaRegListAlt } from "react-icons/fa";
import { BsList } from "react-icons/bs";
import { VscInfo, VscOrganization } from 'react-icons/vsc';
import { AiOutlineUser } from "react-icons/ai";
import { GiWateringCan } from "react-icons/gi";
import { MdLandscape } from "react-icons/md";
import { TbMap2 } from "react-icons/tb";
import { FaTape } from "react-icons/fa";
import { AiOutlineCloudUpload } from "react-icons/ai";
import packageJson from "../../../package.json";

import "./navbar.css";

function Navbar({ showAllSidebar, setShowAllSidebar }) {
	const user = AuthService.getCurrentUser();
	const [tipoSistema, setTipoSistema] = useState(null);

	let getOrganization = useGet(
		`organizacao_consulta?organi_id=${user.organizacao}`,
		user.access_token
	);

	useEffect(() => {
		if (!getOrganization.state.loading) {
			setTipoSistema(getOrganization.state.data[0].tipo_sistema);
		}
	}, [getOrganization]);

	return (
    	<div>
      		<nav className="navbar">
        		<button
          			id="toggle"
          			className="list-button"
          			onClick={() => setShowAllSidebar(!showAllSidebar)}
        		>
          			<BsList size={"2em"} color={"white"} />
        		</button>
        		<img src={exattaLogo} alt={"name-palmaflex"} className="navbar-image" />
      		</nav>
      		<nav
        		className={`sidebar-container ${
          		showAllSidebar ? "showAllSidebar" : ""
        	}`}
      		>
        		<div className="menu-bar">
          			<ul className="menu-links">
						<li title="Dosagem" className="nav-link">
						<a href="/dosing">
							<MdOutlineWaterDrop className="icon" size={"1.5em"} />
							<span className="text nav-text">Dosagem</span>
						</a>
						</li>
						{/* <li title="Dashboard" className="nav-link">
							<a href="/dashboard">
								<MdOutlineDashboard className="icon" size={"1.5em"} />
								<span className="text nav-text">Dashboard</span>
							</a>
						</li> */}
						<li title="Árvore de Dispositivos" className="nav-link">
							<a href="/device-tree">
								<FaRegListAlt className="icon" size={"1.5em"} />
								<span className="text nav-text">Árvore de Dispositivos</span>
							</a>
						</li>
						<li title="Gateways" className="nav-link">
							<a href="/gateways">
								<MdOutlineRouter className="icon" size={"1.5em"} />
								<span className="text nav-text">Concentradores</span>
							</a>
						</li>
						{/*<li title="Coletores" className="nav-link">
						<a href="/dataloggers">
							<MdOutlineDeviceHub className="icon" size={"1.5em"} />
							<span className="text nav-text">Coletores</span>
						</a>
						</li>
						<li title="Sensores" className="nav-link">
						<a href="/sensors">
							<MdOutlineSettingsRemote className="icon" size={"1.5em"} />
							<span className="text nav-text">Sensores</span>
						</a>
						</li> */}
						{/* <li title="Pub Espec" className="nav-link">
						<a href="/pub-espec">
							<MdOutlineArticle className="icon" size={"1.5em"} />
							<span className="text nav-text">Pub Espec</span>
						</a>
						</li> */}
						{/* <li title="Dosagem-old" className="nav-link">
						<a href="/dosing-old">
							<MdOutlineWaterDrop className="icon" size={"1.5em"} />
							<span className="text nav-text">Dosagem-old</span>
						</a>
						</li> */}
						{/*{user.nivel === 3 ? (
							<li title="Modelos" className="nav-link">
								<a href="/device-models">
									<MdOutlineCalculate className="icon" size={"1.5em"} />
									<span className="text nav-text">Modelos</span>
								</a>
							</li>
						) : null}
						{user.nivel === 3 ? (
							<li title="Tipos de Sensor" className="nav-link">
								<a href="/sensor-type">
									<FaTape className="icon" size={"1.3em"} />
									<span className="text nav-text">Tipos de Sensor</span>
								</a>
							</li>
						) : null} */}
						<li title="Status Equipamentos" className="nav-link">
							<a href="/device-status">
								<AiOutlineCloudUpload className="icon" size={"1.5em"} />
								<span className="text nav-text">Status Equipamentos</span>
							</a>
						</li>
						{user.nivel === 3 ? (
							<li title="Usuários" className="nav-link">
								<a href="/users">
									<AiOutlineUser className="icon" size={"1.5em"} />
									<span className="text nav-text">Usuários</span>
								</a>
							</li>
						) : (
							<li title="Perfil" className="nav-link">
								<Link
									to={`/users/${user.id}`}
									state={{ userData: user }}
									className="nav-link"
								>
									<AiOutlineUser className="icon" size={"1.5em"} />
									<span className="text nav-text">Perfil</span>
								</Link>
							</li>
						)}
						{user.nivel === 3 ? (
							<li title="Organizações" className="nav-link">
								<a href="/organizations">
									<VscOrganization className="icon" size={"1.5em"} />
									<span className="text nav-text">Organizações</span>
								</a>
							</li>
						) : null}
						<li className="nav-link">
							<a title="Sair" href="/" onClick={() => AuthService.logout()}>
								<FiLogOut className="icon" size={"1.5em"} />
								<span className="text nav-text">Sair</span>
							</a>
						</li>
					</ul>
					<ul className="bottom-links">
						<li className="nav-link">
							<VscInfo className="icon" size={'1.5em'} />
							<span className="text nav-text">
								Versão {packageJson.version}
							</span>
						</li>
					</ul>
				</div>
			</nav>
		</div>
	);
}

export default Navbar;
