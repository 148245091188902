import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/auth";

import { useGet } from "../../../reducers/useGet";

import DataTable from "react-data-table-component";
import AddButton from "../../../components/data-table-custom/add-button";
import SearchBar from "../../../components/data-table-custom/search-bar";
import ExportButton from "../../../components/data-table-custom/export-button";
import downloadCSV from "../../../scripts/export-script";
import { paginationComponentOptions } from "../../../components/data-table-custom/dataTableCustom";
import ErrorMessage from "../../dashboard/messages-panel/error-message";

export default function DataloggerList() {
	const { nivel, organizacao, access_token } = AuthService.getCurrentUser();
	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data); // To be used in the SearchBar
	const [organizationsNameList, setOrganizationsNameList] = useState(null);
	const [gatewaysNameList, setGatewaysNameList] = useState(null);

	let api_request_org, api_request_gtw, api_request_dl;

	switch (nivel) {
		case 2:
			api_request_org = `organizacao_consulta?organi_representante=${organizacao}`;
			api_request_gtw = `gateway_consulta?gateway_representante=${organizacao}`;
			api_request_dl = `coletor_consulta?coletor_representante=${organizacao}`;
			break;

		case 3:
			api_request_org = "organizacao_api";
			api_request_gtw = "gateway_api";
			api_request_dl = "coletor_api";
			break;

		default:
			api_request_org = `organizacao_consulta?organi_id=${organizacao}`;
			api_request_gtw = `gateway_consulta?gateway_organi=${organizacao}`;
			api_request_dl = `coletor_consulta?coletor_organizacao=${organizacao}`;
			break;
	}

	let dataloggersList = useGet(api_request_dl, access_token);
	let organizationsList = useGet(api_request_org, access_token);
	let gatewaysList = useGet(api_request_gtw, access_token);

	useEffect(() => {
		if (
			!dataloggersList.state.loading &&
			!dataloggersList.state.error &&
			!organizationsList.state.loading &&
			!organizationsList.state.error &&
			!gatewaysList.state.loading &&
			!gatewaysList.state.error
		) {
			setData(dataloggersList.state.data);

			setRecords(data);

			let gtw_names_id = gatewaysList.state.data.map((d) => {
				return { id: d.id, nome: d.tag };
			});

			setOrganizationsNameList(organizationsList.state.data);
			setGatewaysNameList(gtw_names_id);
		}
	}, [
		dataloggersList.state.loading,
		dataloggersList.state.data,
		dataloggersList.state.error,
		data,
		organizationsList.state.loading,
		organizationsList.state.data,
		organizationsList.state.error,
		gatewaysList.state.loading,
		gatewaysList.state.data,
		gatewaysList.state.error,
	]);

	const columns = [
		{ name: "id", selector: (row) => row.id, sortable: true },
		{
			name: "ID",
			selector: (row) => row.id_dispositivo,
		},
		{ name: "Tag", selector: (row) => row.tag, sortable: true },
		{
			name: "Número de Série",
			selector: (row) => row.serie,
			sortable: true,
		},
		{
			name: "Organização",
			selector: (row) => {
				let org = organizationsNameList.find(
					(el) => el.id === row.organi_coletor_id
				);

				if (org === undefined) return "";
				else return org.nome;
			},
		},
		{
			name: "Concentrador",
			selector: (row) => {
				let gtw = gatewaysNameList.find(
					(el) => el.id === row.concentrador
				);
				if (gtw === undefined) return "";
				else return gtw.nome;
			},
		},
		{
			name: "Status",
			selector: (row) => (row.status === "on" ? "Ativo" : "Inativo"),
		},
	];

	const openDataloggerDetail = (dataloggerData) => {
		Navigate(`/dataloggers/${dataloggerData.id}`, {
			state: {
				dataloggerData,
				organizationsNameList,
				gatewaysNameList,
			},
		});
	};

	const Export = ({ onExport }) => (
		<ExportButton onExport={onExport} chartPanel={false} />
	);

	return (
		<div className="page">
			{dataloggersList.state.loading &&
			organizationsList.state.loading &&
			gatewaysList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : dataloggersList.state.error ||
			  organizationsList.state.error ||
			  gatewaysList.state.error ? (
				<ErrorMessage />
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar
							records={records}
							setRecords={setRecords}
							data={data}
							keyword={"TAG"}
						/>
						<Export
							onExport={() => downloadCSV(data, "coletores")}
						/>
						{nivel === 3 ? (
							<AddButton
								goTo={"/dataloggers/add-new-datalogger"}
								list={{
									organizationsNameList,
									gatewaysNameList,
								}}
							/>
						) : null}
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhum Coletor encontrado."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(dataloggerData) =>
							nivel === 3
								? openDataloggerDetail(dataloggerData)
								: null
						}
					/>
				</div>
			)}
		</div>
	);
}
