import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { default as ReactLoading } from 'react-loading';
import { BASE_URL } from '../../../services/api';
import AuthService from '../../../services/auth';
import { tipoMsgMap } from '../../admin/items';
import DashboardButtons from '../components/dashboard-buttons/dashboard-buttons';
import ErrorMessage from '../components/messages-panel/error-message/error-message';
import {
	AnalogMode,
	DivisionMode,
	ManualMode,
	MultiplicationMode,
	OrpMode,
	PhMode,
} from '../modes';

const REFRESH_INTERVAL = 300000; // 5 minutos

export default function DosingInformations({ gateway }) {
	const { access_token } = AuthService.getCurrentUser();

	const [data, setData] = useState([]);
	const [pumpModel, setPumpModel] = useState('');
	const [buttons, setButtons] = useState(Array(6).fill(false));
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	// Memoriza o endpoint da API para evitar re-renderizações desnecessárias
	const apiEndpoint = useMemo(() => `${BASE_URL}/dados_api`, []);

	// Processa os dados após a busca
	const processData = useCallback(
		(responseData) => {
			if (!gateway || !responseData) return;

			const filteredResponse = responseData.filter(
				(item) => item.uidCol === gateway.id_dispositivo
			);

			// Ordena dados por timestamp (mais recente primeiro)
			const sortedData = [...filteredResponse].sort((a, b) => b.timestamp - a.timestamp);

			// Define o modelo da bomba com base nos dados mais recentes
			setPumpModel(sortedData[0]?.tipoMsg || '');
			setData(sortedData);

			console.log(sortedData);
		},
		[gateway]
	);

	// Função para buscar dados
	const fetchData = useCallback(async () => {
		if (!access_token) return;

		try {
			setIsLoading(true);
			setError(null);

			const response = await axios.get(apiEndpoint, {
				headers: {
					Authorization: `Bearer ${access_token}`,
				},
			});

			processData(response.data);
		} catch (error) {
			console.error('Erro ao buscar dados:', error);
			setError('Falha ao buscar dados');
		} finally {
			setIsLoading(false);
		}
	}, [access_token, apiEndpoint, processData]);

	// Busca inicial de dados
	useEffect(() => {
		console.log('Buscando dados às:', new Date().toLocaleTimeString());
		fetchData();

		const intervalId = setInterval(() => {
			console.log('Intervalo acionado às:', new Date().toLocaleTimeString());
			fetchData();
		}, REFRESH_INTERVAL);

		return () => clearInterval(intervalId);
	}, [fetchData]);

	// Configura intervalo para atualização de dados
	useEffect(() => {
		const intervalId = setInterval(fetchData, REFRESH_INTERVAL);

		// Limpa o intervalo ao desmontar o componente
		return () => clearInterval(intervalId);
	}, [fetchData]);

	// Manipulador de alternância de botão
	const toggleButton = useCallback((index) => {
		setButtons((prev) => prev.map((btn, i) => (i === index ? !btn : false)));
	}, []);

	// Renderiza o componente de modo apropriado
	const renderButton = useCallback(
		(mode) => {
			if (mode === undefined || mode === null) return null;

			const modeIndex = parseInt(mode);
			const modes = [ManualMode, MultiplicationMode, DivisionMode, AnalogMode, PhMode, OrpMode];

			const ModeComponent = modes[modeIndex];
			if (!ModeComponent) return null;

			return (
				<div className="dropdown-button-container" key={`mode-${modeIndex}`}>
					<DashboardButtons
						buttonType={'diario'}
						buttonNumber={buttons[modeIndex]}
						setButtonNumber={() => toggleButton(modeIndex)}
						buttonTitle={`Dados Modo ${tipoMsgMap[modeIndex]}`}
						reference={gateway}
					/>
					{buttons[modeIndex] && <ModeComponent data={data} />}
				</div>
			);
		},
		[buttons, data, gateway, toggleButton]
	);

	// Trata o estado de carregamento
	if (isLoading && !data.length) {
		return (
			<div className="loading-container">
				<ReactLoading type="bubbles" color="var(--main-color)" width="50px" height="50px" />
			</div>
		);
	}

	// Trata o estado de erro
	if (error) {
		return <ErrorMessage />;
	}

	return <div>{renderButton(pumpModel)}</div>;
}
