import { useMemo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

export function PhGaugueChartNeedle({ needleValue }) {
	ChartJS.register(ArcElement, Tooltip, Legend);

	const defaultData = {
		labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'],
		datasets: [
			{
				label: '# of Votes',
				data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
				backgroundColor: [
					'#951f49',
					'#c32828',
					'#c15b24',
					'#c36a27',
					'#cb9a5d',
					'#ca9b5c',
					'#c3b127',
					'#c5d52b',
					'#baea93',
					'#48b155',
					'#45b09e',
					'#72a0e2',
					'#9382e9',
					'#6d5ade',
				],
				borderColor: [
					'rgba(0, 0, 0, 1)',
					'rgba(0, 0, 0, 1)',
					'rgba(0, 0, 0, 1)',
					'rgba(0, 0, 0, 1)',
					'rgba(0, 0, 0, 1)',
					'rgba(0, 0, 0, 1)',
					'rgba(0, 0, 0, 1)',
					'rgba(0, 0, 0, 1)',
					'rgba(0, 0, 0, 1)',
					'rgba(0, 0, 0, 1)',
					'rgba(0, 0, 0, 1)',
					'rgba(0, 0, 0, 1)',
					'rgba(0, 0, 0, 1)',
					'rgba(0, 0, 0, 1)',
				],
				borderWidth: 1,
				circumference: 180,
				rotation: 270,
				cutout: '80%',
				needleValue: 0,
			},
		],
	};

	const options = {
		aspectRatio: 1.5,
		layout: {
			padding: 40,
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
		},
	};

	const gaugeNeedle = {
		id: 'gaugeNeedle',
		afterDatasetsDraw(chart, args, options) {
			const { ctx, data } = chart;

			ctx.save();
			const xCenter = chart.getDatasetMeta(0).data[0].x;
			const yCenter = chart.getDatasetMeta(0).data[0].y;
			const outRadius = chart.getDatasetMeta(0).data[0].outerRadius;
			const inRadius = chart.getDatasetMeta(0).data[0].innerRadius;

			const widthSlice = (outRadius - inRadius) / 2;
			const radius = 15;
			const angle = Math.PI / 180;

			const needleValue = data.datasets[0].needleValue;

			const circumference =
				(chart.getDatasetMeta(0).data[0].circumference / Math.PI / data.datasets[0].data[0]) *
				needleValue;

			ctx.translate(xCenter, yCenter);
			ctx.rotate(Math.PI * (circumference + 1.5));

			//needle
			ctx.beginPath();
			ctx.strokeStyle = 'black';
			ctx.fillStyle = 'grey';
			ctx.lineWidth = 1;
			ctx.moveTo(0 - radius, 0);
			ctx.lineTo(0, 0 - inRadius - widthSlice);
			ctx.lineTo(0 + radius, 0);
			ctx.closePath();
			ctx.fill();
			ctx.stroke();

			//dot
			ctx.beginPath();
			ctx.arc(0, 0, radius, 0, angle * 360, false);
			ctx.fill();

			ctx.restore();
		},
	};

	// gaugeFlowMeter plugin block
	const gaugeFlowMeter = {
		id: 'gaugeFlowMeter',
		afterDatasetsDraw(chart, args, plugins) {
			const { ctx, data } = chart;

			ctx.save();
			const needleValue = data.datasets[0].needleValue;
			const xCenter = chart.getDatasetMeta(0).data[0].x;
			const yCenter = chart.getDatasetMeta(0).data[0].y;

			const circumference =
				(chart.getDatasetMeta(0).data[0].circumference / Math.PI / data.datasets[0].data[0]) *
				needleValue;

			const currentPhValue = circumference * 14;

			// flowMeter
			ctx.font = 'bold 30px sans-serif';
			ctx.fillStyle = 'black';
			ctx.textAlign = 'center';
			ctx.fillText(`${currentPhValue.toFixed(1)}`, xCenter, yCenter + 45);
		},
	};

	// gaugeLabels plugin block
	const gaugeLabels = {
		id: 'gaugeLabels',
		afterDatasetsDraw(chart, args, plugins) {
			const { ctx } = chart;

			const xCenter = chart.getDatasetMeta(0).data[0].x;
			const yCenter = chart.getDatasetMeta(0).data[0].y;
			const outRadius = chart.getDatasetMeta(0).data[0].outerRadius;
			const inRadius = chart.getDatasetMeta(0).data[0].innerRadius;
			const widthSlice = (outRadius - inRadius) / 2;

			ctx.translate(xCenter, yCenter);
			ctx.font = 'bold 15px sans-serif';
			ctx.fillStyle = 'black';
			ctx.textAlign = 'center';
			ctx.fillText('ÁCIDO', 0 - inRadius - widthSlice, 0 + 30);
			ctx.fillText('ALCALINO', 0 + inRadius + widthSlice, 0 + 30);
			ctx.fillText('NEUTRO', 0, 0 - 175);

			ctx.restore();
		},
	};

	const plugins = [gaugeNeedle, gaugeFlowMeter, gaugeLabels];

	const data = useMemo(() => {
		const newData = { ...defaultData };
		newData.datasets[0].needleValue = needleValue * 100;
		return newData;
	}, [needleValue]);

	return <Doughnut data={data} options={options} plugins={plugins} />;
}
