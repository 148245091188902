import '../styles.css';

export default function OperationFlux({ data }) {
	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">Fluxo de Operação</em>
			</div>
			<div className="show-basic-data-container">
				<div className="progress-container">
					<div className="progress-bar" style={{ width: `${data[0].ValorSns_1}%` }} />
					<span className="progress-percentage">{`${data[0].ValorSns_1}%`}</span>
				</div>
			</div>
		</div>
	);
}
