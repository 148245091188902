import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	Outlet,
	useLocation,
} from "react-router-dom";

import AuthService from "./services/auth";
import Login from "./components/login";
import Admin from "./components/admin";
import {
	RegisterPassword,
	ForgotPassword,
	UpdatePassword,
} from "./components/password-panels";

function App() {
	const user = AuthService.getCurrentUser();

	let PrivateRoutes = () => {
		const location = useLocation();

		if (user === undefined) return null;

		return user ? (
			<Outlet />
		) : (
			<Navigate to="/" replace state={{ from: location }} />
		);
	};

	return (
		<Router>
			<Routes>
				<Route element={<PrivateRoutes />}>
					<Route path="*" element={<Admin />} />
				</Route>
				<Route
					path="/"
					element={user ? <Navigate to="/dosing" /> : <Login />}
				/>
				<Route
					path="/register-password/:token"
					element={<RegisterPassword />}
				/>
				<Route path="/login-help" element={<ForgotPassword />} />
				<Route
					path="/update-password/:token"
					element={<UpdatePassword />}
				/>
			</Routes>
		</Router>
	);
}

export default App;
