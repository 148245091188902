import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/auth";

import { useGet } from "../../../reducers/useGet";

import DataTable from "react-data-table-component";
import AddButton from "../../../components/data-table-custom/add-button";
import SearchBar from "../../../components/data-table-custom/search-bar";
import ExportButton from "../../../components/data-table-custom/export-button";
import downloadCSV from "../../../scripts/export-script";
import { paginationComponentOptions } from "../../../components/data-table-custom/dataTableCustom";
import ErrorMessage from "../../dashboard/messages-panel/error-message";

export default function OrganizationList() {
	let { access_token } = AuthService.getCurrentUser();
	let organizationsList = useGet("organizacao_api", access_token);

	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data); // To be used in the SearchBar

	useEffect(() => {
		if (
			!organizationsList.state.loading &&
			!organizationsList.state.error
		) {
			setData(organizationsList.state.data);
			setRecords(data);
		}
	}, [
		organizationsList.state.loading,
		organizationsList.state.error,
		organizationsList.state.data,
		data,
	]);

	const columns = [
		{ name: "ID", selector: (row) => row.id, sortable: true },
		{ name: "Nome", selector: (row) => row.nome, sortable: true },
		{
			name: "Representante",
			selector: (row) => {
				let org = data.find((el) => el.id === +row.representante);
				if (org === undefined) return "";
				else return org.nome;
			},
		},
		{
			name: "Papel",
			selector: (row) => row.nivel,
		},
	];

	const openOrganizationDetail = (organizationData) => {
		Navigate(`/organizations/${organizationData.id}`, {
			state: {
				organizationData,
				organizationsNameList: data,
			},
		});
	};

	const Export = ({ onExport }) => (
		<ExportButton onExport={onExport} chartPanel={false} />
	);

	return (
		<div className="page">
			{organizationsList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : organizationsList.state.error ? (
				<ErrorMessage />
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar
							records={records}
							setRecords={setRecords}
							data={data}
							keyword={"NAME"}
						/>
						<Export
							onExport={() => downloadCSV(data, "organizacoes")}
						/>
						<AddButton
							goTo={"/organizations/add-new-organization"}
							list={data}
						/>
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhuma Organização encontrada."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(organizationData) =>
							openOrganizationDetail(organizationData)
						}
					/>
				</div>
			)}
		</div>
	);
}
