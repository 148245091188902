import React, { useEffect, useState, useMemo } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";

import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import { paginationComponentOptions } from "../../../data-table-custom/dataTableCustom";
import ExportButton from "../../../data-table-custom/export-button";
import downloadCSV from "../../../../scripts/export-script";

import ErrorMessage from "../../messages-panel/error-message";

import SelectDataButton from "../../date-selector";

import moment from "moment";

export default function HydrologicalBalanceTablePanel({ zonaManejo, gateway }) {
	const { access_token } = AuthService.getCurrentUser();
	const [periodo1, setPeriodo1] = useState("");
	const [periodo2, setPeriodo2] = useState("");
	const [data, setData] = useState([]);

	let api_request;
	if (periodo1 === "") {
		let lastWeek = new Date();
		lastWeek.setDate(lastWeek.getDate() - 7);
		lastWeek = moment(lastWeek).format("YYYY-MM-DD");
		api_request = `dados_zona_manejo_consulta?id_zm=${zonaManejo.id}&data:>=${lastWeek}`;
		setPeriodo1(new Date(lastWeek));
	} else {
		let data1 = moment(periodo1).format("YYYY-MM-DD");
		if (periodo2 !== "") {
			let data2 = moment(periodo2).format("YYYY-MM-DD");
			api_request = `dados_zona_manejo_consulta?id_zm=${zonaManejo.id}&data:>=${data1}&data:<=${data2}`;
		} else {
			api_request = `dados_zona_manejo_consulta?id_zm=${zonaManejo.id}&data:>=${data1}`;
		}
	}

	let getData = useGet(api_request, access_token);

	useEffect(() => {
		if (!getData.state.loading && !getData.state.error) {
			// Remove dados duplicados
			let unique = getData.state.data
				.map((e) => moment(e["data"]).format("YYYY-MM-DD"))
				.map((e, i, final) => final.indexOf(e) === i && i)
				.filter((obj) => getData.state.data[obj])
				.map((e) => getData.state.data[e]);

			let convertData = unique.map((d) => {
				return {
					Data: d.data,
					AD20: d.ad20 != null ? +d.ad20 : null,
					AD60: d.ad60 != null ? +d.ad60 : null,
					ARM: d.arm != null ? +d.arm : null,
					ARM20: d.arm20 != null ? +d.arm20 : null,
					CAD: d.cad != null ? +d.cad : null,
					ETc: d.etc != null ? +d.etc : null,
					LB: d.lb != null ? +d.lb : null,
					max_agua_raiz:
						d.max_agua_raiz != null ? +d.max_agua_raiz : null,
					min_agua_raiz:
						d.min_agua_raiz != null ? +d.min_agua_raiz : null,
					Rperc: d.r_perc != null ? +d.r_perc : null,
					Ti: d.ti != null ? +d.ti : null,
					dias_decorridos:
						d.dias_decorridos != null ? +d.dias_decorridos : null,
					fase_fenologica: d.fase_fenologica,
					kc: d.kc != null ? +d.kc : null,
					z: d.z != null ? +d.z : null,
					t20: d.valor_t20 != null ? +d.valor_t20 : null,
					t40: d.valor_t40 != null ? +d.valor_t40 : null,
					t60: d.valor_t60 != null ? +d.valor_t60 : null,
				};
			});

			convertData = convertData.sort(
				(d1, d2) =>
					new Date(d1.Data).getTime() - new Date(d2.Data).getTime()
			);

			setData(convertData);
		}
	}, [getData.state.loading, getData.state.error, getData.state.data]);

	const columns = [
		{
			name: "Data",
			selector: (row) => moment(row.Data).format("DD-MM-YY"),
			sortable: true,
		},
		{
			name: "Fase Fenológica",
			selector: (row) => row.fase_fenologica,
			omit: data.every((item) => !item.fase_fenologica),
		},
		{
			name: "Dias Decorridos",
			selector: (row) =>
				row.dias_decorridos ? row.dias_decorridos : null,
			omit: data.every((item) => !item.dias_decorridos),
		},
		{
			name: "Kc",
			selector: (row) => (row.kc ? row.kc : null),
			omit: data.every((item) => !item.kc),
		},
		{
			name: "Profundidade da Raiz (cm)",
			selector: (row) => (row.z ? row.z : null),
			omit: data.every((item) => !item.z),
		},
		{
			name: "Tensiômetro de 20cm às 6h (kPa)",
			selector: (row) => (row.t20 !== null ? row.t20 : null),
			omit: data.every((item) => !item.t20),
		},
		{
			name: "Tensiômetro de 40cm às 6h (kPa)",
			selector: (row) => (row.t40 !== null ? row.t40 : null),
			omit: data.every((item) => !item.t40),
		},
		{
			name: "Tensiômetro de 60cm às 6h (kPa)",
			selector: (row) => (row.t60 !== null ? row.t60 : null),
			omit: data.every((item) => !item.t60),
		},
		{
			name: "AD até 20cm (%)",
			selector: (row) => (row.AD20 !== null ? row.AD20 : null),
			omit: data.every((item) => !item.AD20),
		},
		{
			name: "AD em todo Sistema Radicular (%)",
			selector: (row) => (row.AD60 !== null ? row.AD60 : null),
			omit: data.every((item) => !item.AD60),
		},
		{
			name: "ARM (mm)",
			selector: (row) => (row.ARM !== null ? row.ARM : null),
			omit: data.every((item) => !item.ARM),
		},
		{
			name: "ARM20 (mm)",
			selector: (row) => (row.ARM20 !== null ? row.ARM20 : null),
			omit: data.every((item) => !item.ARM20),
		},
		{
			name: "CAD (%)",
			selector: (row) => (row.CAD !== null ? row.CAD : null),
			omit: data.every((item) => !item.CAD),
		},
		{
			name: "ETc (mm)",
			selector: (row) => (row.ETc !== null ? row.ETc : null),
			omit: data.every((item) => !item.ETc),
		},
		{
			name: "LB (mm)",
			selector: (row) => (row.LB !== null ? row.LB : null),
			omit: data.every((item) => !item.LB),
		},
		{
			name: "max_agua_raiz (mm)",
			selector: (row) =>
				row.max_agua_raiz !== null ? row.max_agua_raiz : null,
			omit: data.every((item) => !item.max_agua_raiz),
		},
		{
			name: "min_agua_raiz (mm)",
			selector: (row) =>
				row.min_agua_raiz !== null ? row.min_agua_raiz : null,
			omit: data.every((item) => !item.min_agua_raiz),
		},
		{
			name: "Rperc",
			selector: (row) => (row.Rperc !== null ? row.Rperc : null),
			omit: data.every((item) => !item.Rperc),
		},
		{
			name: "Tempo de Irrigação Estimado (min)",
			selector: (row) => (row.Ti !== null ? row.Ti : null),
			omit: data.every((item) => !item.Ti),
		},
	];

	const Export = ({ onExport }) => (
		<ExportButton onExport={onExport} chartPanel={false} />
	);

	const actionsMemo = useMemo(
		() => (
			<Export
				onExport={() => downloadCSV(data, "dados-balanco-hidrico")}
			/>
		),
		[data]
	);

	return (
		<div
			className="big-panel-container"
			style={{ margin: "10px 50px 10px 50px" }}
		>
			<div className="panel-title-container">
				<em className="panel-title">Compilado dos Dados por Dia</em>
			</div>

			<div style={{ margin: "10px" }}>
				{getData.state.loading ? (
					<div className="panel-loading">
						<ReactLoading
							type="spinningBubbles"
							color="var(--main-color)"
							width="50px"
							height="50px"
						/>
					</div>
				) : getData.state.error ? (
					<ErrorMessage />
				) : (
					<div style={{ margin: "0px 20px 0px 20px" }}>
						<SelectDataButton
							periodo1={periodo1}
							setPeriodo1={setPeriodo1}
							periodo2={periodo2}
							setPeriodo2={setPeriodo2}
							hourlyData={false}
						/>
						<DataTable
							columns={columns}
							data={data}
							noDataComponent="Nenhum dado encontrado."
							fixedHeader
							pagination
							paginationComponentOptions={
								paginationComponentOptions
							}
							striped={true}
							actions={actionsMemo}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
