import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useGet } from '../../reducers/useGet';
import AuthService from '../../services/auth';
import DashboardHeader from './components/dashboard-header';
import ErrorMessage from './components/messages-panel/error-message';
import NoDeviceConnectedMessage from './components/messages-panel/no-device-connected-message';
import DosingInformations from './dosing-informations';
import './dosing-styles.css';

export default function Dosing() {
	const { organizacao, access_token, nivel } = AuthService.getCurrentUser();
	const [gatewayList, setGatewayList] = useState([]);
	const [gateway, setGateway] = useState(null);
	const [tipoSistema, setTipoSistema] = useState(null);

	let endpoint_gtw, endpoint_organizations;
	switch (nivel) {
		case 2:
			endpoint_organizations = `organizacao_consulta?organi_representante=${organizacao}`;
			endpoint_gtw = `gateway_consulta?gateway_representante=${organizacao}`;
			break;

		case 3:
			endpoint_organizations = `organizacao_api`;
			endpoint_gtw = 'gateway_api';
			break;

		default:
			endpoint_organizations = `organizacao_consulta?organi_id=${organizacao}`;
			endpoint_gtw = `gateway_consulta?gateway_organi=${organizacao}`;
			break;
	}

	let getOrganizations = useGet(endpoint_organizations, access_token);

	let getGateways = useGet(endpoint_gtw, access_token, true);

	useEffect(() => {
		if (
			!getOrganizations.state.loading &&
			!getOrganizations.state.error &&
			!getGateways.state.loading &&
			!getGateways.state.error
		) {
			let gtw_names_id = getGateways.state.data
				.filter((d) => d.id >= 57)
				.map((d) => {
					return {
						id: d.id,
						nome: d.tag,
						id_dispositivo: d.id_dispositivo,
						organization_id: d.organi_concentra_id,
					};
				});

			if (nivel < 2) setTipoSistema(getOrganizations.state.data[0].tipo_sistema);
			setGatewayList(gtw_names_id);
		}
	}, [
		nivel,
		getOrganizations.state.loading,
		getOrganizations.state.error,
		getOrganizations.state.data,
		getGateways.state.loading,
		getGateways.state.error,
		getGateways.state.data,
	]);

	useEffect(() => {
		if (nivel >= 2 && gateway) {
			let gtw_org = getOrganizations.state.data.filter((d) => gateway.organization_id === d.id);

			setTipoSistema(gtw_org[0].tipo_sistema);
		}
	}, [nivel, gateway, getOrganizations.state.data]);

	return (
		<div className="page">
			{getOrganizations.state.loading && getGateways.state.loading ? (
				<div className="loading-container">
					<ReactLoading type="bubbles" color="var(--main-color)" width="50px" height="50px" />
				</div>
			) : getGateways.state.data.length === 0 ? (
				<div className="message-panel">
					<NoDeviceConnectedMessage />
				</div>
			) : getOrganizations.state.error || getGateways.state.error ? (
				<ErrorMessage />
			) : (
				<div>
					<DashboardHeader
						item={gateway}
						setItem={setGateway}
						itemsList={gatewayList}
						headerText={'Dados referentes a Estação'}
					/>
					<DosingInformations gateway={gateway} />
				</div>
			)}
		</div>
	);
}
