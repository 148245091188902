import React, { useEffect, useState, useMemo } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";

import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import { paginationComponentOptions } from "../../../data-table-custom/dataTableCustom";
import ExportButton from "../../../data-table-custom/export-button";
import downloadCSV from "../../../../scripts/export-script";

import ErrorMessage from "../../messages-panel/error-message";

import SelectDataButton from "../../date-selector";

import moment from "moment";

export default function DailyDataTablePanel({ gateway }) {
	const { access_token } = AuthService.getCurrentUser();
	const [periodo1, setPeriodo1] = useState("");
	const [periodo2, setPeriodo2] = useState("");
	const [data, setData] = useState([]);

	let api_request;
	if (periodo1 === "") {
		let lastWeek = new Date();
		lastWeek.setDate(lastWeek.getDate() - 7);
		lastWeek = moment(lastWeek).format("YYYY-MM-DD");
		api_request = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${lastWeek}`;
		setPeriodo1(new Date(lastWeek));
	} else {
		let data1 = moment(periodo1).format("YYYY-MM-DD");
		if (periodo2 !== "") {
			let data2 = moment(periodo2).format("YYYY-MM-DD");
			api_request = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
		} else {
			api_request = `dados_diarios_consulta?gateway=${gateway.id_dispositivo}&data:>=${data1}`;
		}
	}

	let getData = useGet(api_request, access_token);

	useEffect(() => {
		if (!getData.state.loading && !getData.state.error) {
			// Remove dados duplicados
			let unique = getData.state.data
				.map((e) => moment(e["data"]).format("YYYY-MM-DD"))
				.map((e, i, final) => final.indexOf(e) === i && i)
				.filter((obj) => getData.state.data[obj])
				.map((e) => getData.state.data[e]);

			let convertData = unique.map((d) => {
				return {
					Data: d.data,
					Tmax: d.Tmax ? +d.Tmax : "",
					Tmed: d.Tmed ? +d.Tmed : "",
					Tmin: d.Tmin ? +d.Tmin : "",
					URmax: d.URmax ? +d.URmax : "",
					URmed: d.URmed ? +d.URmed : "",
					URmin: d.URmin ? +d.URmin : "",
					Vmed: d.Vmed ? +d.Vmed : "",
					RSmed: d.RSmed ? +d.RSmed : "",
					Pacc: d.Pacc ? +d.Pacc : "",
					Eto: d.Eto ? +d.Eto : "",
					Etc: d.Etc ? +d.Etc : "",
				};
			});

			setData(convertData);
		}
	}, [getData.state.loading, getData.state.error, getData.state.data]);

	const columns = [
		{
			name: "Data",
			selector: (row) => moment(row.Data).format("DD-MM-YY"),
			sortable: true,
		},
		{
			name: "Tmax (ºC)",
			selector: (row) => (row.Tmax !== null ? row.Tmax.toFixed(1) : null),
			omit: data.every((item) => !item.Tmax),
		},
		{
			name: "Tmed (ºC)",
			selector: (row) => (row.Tmed !== null ? row.Tmed.toFixed(1) : null),
			omit: data.every((item) => !item.Tmed),
		},
		{
			name: "Tmin (ºC)",
			selector: (row) => (row.Tmin !== null ? row.Tmin.toFixed(1) : null),
			omit: data.every((item) => !item.Tmin),
		},
		{
			name: "URmax (%)",
			selector: (row) =>
				row.URmax !== null ? row.URmax.toFixed(1) : null,
			omit: data.every((item) => !item.URmax),
		},
		{
			name: "URmed (%)",
			selector: (row) =>
				row.URmed !== null ? row.URmed.toFixed(1) : null,
			omit: data.every((item) => !item.URmed),
		},
		{
			name: "URmin (%)",
			selector: (row) =>
				row.URmin !== null ? row.URmin.toFixed(1) : null,
			omit: data.every((item) => !item.URmin),
		},
		{
			name: "Vmed (km/h)",
			selector: (row) => (row.Vmed !== null ? row.Vmed.toFixed(1) : null),
			omit: data.every((item) => !item.Vmed),
		},
		{
			name: "RSmed (km/h)",
			selector: (row) =>
				row.RSmed !== null ? row.RSmed.toFixed(1) : null,
			omit: data.every((item) => !item.RSmed),
		},
		{
			name: "Pluviometria (mm)",
			selector: (row) => (row.Pacc !== null ? row.Pacc.toFixed(1) : null),
			omit: data.every((item) => isNaN(item.Pacc)),
		},
		{
			name: "ETo (mm)",
			selector: (row) => (row.Eto !== null ? row.Eto.toFixed(1) : null),
			omit: data.every((item) => !item.Eto),
		},
		{
			name: "ETc (mm)",
			selector: (row) => (row.Etc !== null ? row.Etc.toFixed(1) : null),
			omit: data.every((item) => !item.Etc),
		},
	];

	const Export = ({ onExport }) => (
		<ExportButton onExport={onExport} chartPanel={false} />
	);

	const actionsMemo = useMemo(
		() => <Export onExport={() => downloadCSV(data, "dados-diarios")} />,
		[data]
	);

	return (
		<div
			className="big-panel-container"
			style={{ margin: "10px 50px 10px 50px" }}
		>
			<div className="panel-title-container">
				<em className="panel-title">Compilado dos Dados por Dia</em>
			</div>

			<div style={{ margin: "10px" }}>
				{getData.state.loading ? (
					<div className="panel-loading">
						<ReactLoading
							type="spinningBubbles"
							color="var(--main-color)"
							width="50px"
							height="50px"
						/>
					</div>
				) : getData.state.error ? (
					<ErrorMessage />
				) : (
					<div style={{ margin: "0px 20px 0px 20px" }}>
						<SelectDataButton
							periodo1={periodo1}
							setPeriodo1={setPeriodo1}
							periodo2={periodo2}
							setPeriodo2={setPeriodo2}
							hourlyData={false}
						/>
						<DataTable
							columns={columns}
							data={data}
							noDataComponent="Nenhum dado encontrado."
							fixedHeader
							pagination
							paginationComponentOptions={
								paginationComponentOptions
							}
							striped={true}
							actions={actionsMemo}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
