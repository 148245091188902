import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGet } from "../../reducers/useGet";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

import exattaLogo from "../../assets/exattaLogo.png";
import SendPasswordButton from "../data-table-custom/save-password-button";

import "./password-panels-styles.css";

export default function RegisterPassword() {
	const { token } = useParams();

	const [userData, setUserData] = useState(null);
	const [password, setPassword] = useState("");
	const [hidePassword, setHidePassword] = useState(true);

	let userGetData = useGet(`user_consulta?user_token=${token}`, token);

	useEffect(() => {
		if (!userGetData.state.loading) setUserData(userGetData.state.data[0]);
	}, [userGetData.state.loading, userGetData.state.data]);

	let infoColor = "black";
	if (password.length >= 8) {
		infoColor = "green";
		userData.senha = password;
	} else if (password.length < 8 && password.length >= 1) {
		infoColor = "red";
	}

	return (
		<div>
			<nav className="navbar">
				<img
					src={exattaLogo}
					alt={"exatta-name-logo"}
					className="navbar-image"
				/>
			</nav>
			<div className="password-page-container">
				<div className="password-panel">
					<span className="page-title">Bem vindo(a) ao Sistema Exatta!</span>

					<p className="p-password">
						Cadastre a sua senha para acessar a Plataforma
					</p>

					<div className="password-input-container">
						<input
							className="password-input-style "
							type={hidePassword ? "password" : "text"}
							placeholder="Senha"
							name="senha"
							onChange={(e) => setPassword(e.target.value)}
						/>
						<button
							className="show-hide-password-button"
							onClick={() => setHidePassword(hidePassword ? false : true)}
						>
							{hidePassword ? (
								<BsEyeSlashFill name="eye-slash" size={16} />
							) : (
								<BsEyeFill name="eye" size={16} />
							)}
						</button>
					</div>
					<span
						style={{
							marginTop: "10px",
							fontSize: "12px",
							color: `${infoColor}`,
						}}
					>
						A senha deve conter no mínimo 8 caracteres.
					</span>

					<div style={{ marginBottom: "40px" }}></div>

					{password.length < 8 ? null : (
						<SendPasswordButton
							text={"Enviar"}
							data={userData}
							userId={userData.id}
							token={token}
							returnTo="http://dev-exatta.s3-website-us-east-1.amazonaws.com/dosing"
						/>
					)}
				</div>
			</div>
		</div>
	);
}
