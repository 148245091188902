import React, { useEffect, useState } from "react";
import AuthService from "../../../../services/auth";
import { useGet } from "../../../../reducers/useGet";
import ReactLoading from "react-loading";

import ExportButton from "../../../data-table-custom/export-button";
import downloadCSV from "../../../../scripts/export-script";

import SelectDataButton from "../../date-selector";
import NoDataAvailableMessage from "../../messages-panel/no-data-available-message/no-data-available-message";
import ErrorMessage from "../../messages-panel/error-message";

import { tempSensor, rhSensor } from "../../../../sensors-type";

import { LineChart2Elements2Axis } from "../../charts";
import moment from "moment";

import thermometerIcon from "../../../../assets/icons/thermometer-icon.png";
import humidityIcon from "../../../../assets/icons/humidity-icon.png";
import { BiDotsHorizontal } from "react-icons/bi";

import "../panel-styles.css";

export default function THDataAnimalPanel({ sensors }) {
	const { access_token } = AuthService.getCurrentUser();
	const [periodo1, setPeriodo1] = useState("");
	const [periodo2, setPeriodo2] = useState("");
	const [exportData, setExportData] = useState([]);
	const [tempArray, setTempArray] = useState([]);
	const [urArray, setURArray] = useState([]);
	const [tempLastData, setTempLastData] = useState(null);
	const [urLastData, setURLastData] = useState(null);

	let sensorTemp, sensorUR;
	let api_request_temp, api_request_ur;

	// Procura pelos sensores de temperatura e umidade relativa para
	// se fazer os requests dos dados medidos
	sensors.forEach((s) => {
		if (s.tipo === tempSensor) sensorTemp = s;
		else if (s.tipo === rhSensor) sensorUR = s;
	});

	if (periodo1 === "") {
		let lastWeek = new Date();
		lastWeek.setDate(lastWeek.getDate() - 5);
		lastWeek = moment(lastWeek).format("YYYY-MM-DDT03:00");
		api_request_temp = `evento_consulta?tipo_mensagem=1&sensor=${sensorTemp.id_dispositivo}&data:>=${lastWeek}`;
		api_request_ur = `evento_consulta?tipo_mensagem=1&sensor=${sensorUR.id_dispositivo}&data:>=${lastWeek}`;
		setPeriodo1(new Date(moment(lastWeek).format("YYYY-MM-DDT00:00")));
	} else {
		let data1 = moment(periodo1).utc().format("YYYY-MM-DDTHH:mm");

		if (periodo2 !== "") {
			let data2 = moment(periodo2).utc().format("YYYY-MM-DDTHH:mm");
			api_request_temp = `evento_consulta?tipo_mensagem=1&sensor=${sensorTemp.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
			api_request_ur = `evento_consulta?tipo_mensagem=1&sensor=${sensorUR.id_dispositivo}&data:>=${data1}&data:<=${data2}`;
		} else {
			api_request_temp = `evento_consulta?tipo_mensagem=1&sensor=${sensorTemp.id_dispositivo}&data:>=${data1}`;
			api_request_ur = `evento_consulta?tipo_mensagem=1&sensor=${sensorUR.id_dispositivo}&data:>=${data1}`;
		}
	}

	let getTempData = useGet(api_request_temp, access_token);
	let getRHData = useGet(api_request_ur, access_token);

	let getTempLastData = useGet(
		`evento_consulta?tipo_mensagem=1&sensor=${sensorTemp.id_dispositivo}&ultimo_dado=1`,
		access_token
	);
	let getURLastData = useGet(
		`evento_consulta?tipo_mensagem=1&sensor=${sensorUR.id_dispositivo}&ultimo_dado=1`,
		access_token
	);

	useEffect(() => {
		let tArray = [];
		let rhArray = [];
		let exportDataAux = [];
		let aux = [];

		if (
			!getTempData.state.loading &&
			!getTempData.state.error &&
			!getRHData.state.loading &&
			!getRHData.state.error &&
			!getTempLastData.state.loading &&
			!getTempLastData.state.error &&
			!getURLastData.state.loading &&
			!getURLastData.state.error &&
			getTempData.state.data.length > 0 &&
			getURLastData.state.data.length > 0
		) {
			tArray = getTempData.state.data.map((d) => {
				aux.push({
					id: d.id,
					data: moment
						.unix(d.timestamp / 1000)
						.format("DD/MM/YY HH:mm"),
					temperatura: +d.valor_sensor,
				});

				return {
					timestamp: moment
						.unix(d.timestamp / 1000)
						.format("YYYY-MM-DDTHH:mm:ss"),
					value: +d.valor_sensor,
				};
			});
			rhArray = getRHData.state.data.map((d) => {
				let objectExist = aux.find((obj) => obj.id === d.id);

				if (objectExist)
					exportDataAux.push({
						id: objectExist.id,
						data: objectExist.data,
						temperatura: objectExist.temperatura,
						umidade: +d.valor_sensor,
					});

				return {
					timestamp: moment
						.unix(d.timestamp / 1000)
						.format("YYYY-MM-DDTHH:mm:ss"),
					value: +d.valor_sensor,
				};
			});

			tArray = tArray.sort(
				(d1, d2) =>
					new Date(d1.timestamp).getTime() -
					new Date(d2.timestamp).getTime()
			);

			rhArray = rhArray.sort(
				(d1, d2) =>
					new Date(d1.timestamp).getTime() -
					new Date(d2.timestamp).getTime()
			);

			exportDataAux = exportDataAux.map((objeto) => {
				const { id: propriedadeRemovida, ...restoDoObjeto } = objeto;
				return restoDoObjeto;
			});

			exportDataAux = exportDataAux.sort(
				(d1, d2) =>
					new Date(d1.data).getTime() - new Date(d2.data).getTime()
			);
		}

		setExportData(exportDataAux);
		setTempLastData(getTempLastData.state.data[0]);
		setURLastData(getURLastData.state.data[0]);
		setTempArray(tArray);
		setURArray(rhArray);
	}, [
		getTempData.state.loading,
		getTempData.state.error,
		getTempData.state.data,
		getRHData.state.loading,
		getRHData.state.error,
		getRHData.state.data,
		getTempLastData.state.loading,
		getTempLastData.state.error,
		getTempLastData.state.data,
		getURLastData.state.loading,
		getURLastData.state.error,
		getURLastData.state.data,
	]);

	const Export = ({ onExport }) => (
		<ExportButton onExport={onExport} chartPanel={true} />
	);

	return (
		<div className="little-panel-container">
			<div className="panel-title-container">
				<em className="panel-title">
					Dados de Temperatura e Umidade Relativa
				</em>
			</div>
			<div>
				{(getTempData.state.loading &&
					getRHData.state.loading &&
					getTempLastData.state.loading &&
					getURLastData.state.loading) ||
				tempLastData === undefined ||
				urLastData === undefined ? (
					<div className="panel-loading">
						<ReactLoading
							type="spinningBubbles"
							color="var(--main-color)"
							width="50px"
							height="50px"
						/>
					</div>
				) : getTempData.state.error ||
				  getRHData.state.error ||
				  getTempLastData.state.error ||
				  getURLastData.state.error ? (
					<ErrorMessage />
				) : (
					<div style={{ margin: "10px" }}>
						<div className="panel-items">
							<BiDotsHorizontal />
							<span className="panel-items-title">
								Últimos Valores
							</span>
						</div>
						<div className="last-data-container">
							<div className="last-data-content-container">
								<img
									src={thermometerIcon}
									height={"30px"}
									width={"30px"}
									alt=""
									style={{ marginRight: "5px" }}
								/>
								{!isNaN(+tempLastData.valor_sensor) ? (
									<p className="last-value-text">
										{+tempLastData.valor_sensor} ºC
									</p>
								) : (
									<p className="last-value-text">-- ºC</p>
								)}

								<img
									src={humidityIcon}
									height={"30px"}
									width={"30px"}
									alt=""
									style={{
										marginRight: "5px",
										marginLeft: "20px",
									}}
								/>
								{!isNaN(+urLastData.valor_sensor) ? (
									<p className="last-value-text">
										{+urLastData.valor_sensor} %
									</p>
								) : (
									<p className="last-value-text">-- %</p>
								)}
							</div>
							<span style={{ fontSize: "10px" }}>
								Dados atualizados em{" "}
								{moment(urLastData.data).format(
									"DD-MM-YY HH:mm"
								)}
							</span>
						</div>

						<div className="panel-items">
							<BiDotsHorizontal />
							<span className="panel-items-title">
								Gráfico Temperatura x Umidade Relativa
							</span>
						</div>

						<SelectDataButton
							periodo1={periodo1}
							setPeriodo1={setPeriodo1}
							periodo2={periodo2}
							setPeriodo2={setPeriodo2}
							hourlyData={true}
						/>

						{tempArray.length > 0 || urArray.length > 0 ? (
							<div className="chart-container">
								<LineChart2Elements2Axis
									data1={tempArray}
									data2={urArray}
									name1={"Temperatura"}
									name2={"Umidade Relativa"}
									unit1={"ºC"}
									unit2={"%"}
									color1={"#FF8000"}
									color2={"#004C99"}
									fileName={
										"grafico-temperatura-e-umidade-relativa"
									}
								/>
							</div>
						) : (
							<NoDataAvailableMessage />
						)}
						{tempArray.length > 0 || urArray.length > 0 ? (
							<div className="panel-items">
								<BiDotsHorizontal />
								<span className="panel-items-title">
									Exportar dados do Gráfico
								</span>
								<div className="right-container">
									<Export
										onExport={() =>
											downloadCSV(
												exportData,
												`dados-temperatura-NS${sensorTemp.serie}-umidade-NS${sensorUR.serie}`
											)
										}
									/>
								</div>
							</div>
						) : null}
					</div>
				)}
			</div>
		</div>
	);
}
