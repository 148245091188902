import React, { useState } from "react";

import exattaLogo from "../../assets/exattaLogo.png";
import SendEmailForgetPassword from "../data-table-custom/send-email-forget-password.jsx";

import "./password-panels-styles.css";

export default function ForgotPassword() {
	const [email, setEmail] = useState("");

	return (
		<div>
			<nav className="navbar">
				<img
					src={exattaLogo}
					alt={"exatta-name-logo"}
					className="navbar-image"
				/>
			</nav>
			<div className="password-page-container">
				<div className="password-panel">
					<span className="page-title">Esqueceu a senha?</span>

					<input
						className="email-input-style "
						placeholder="E-mail"
						name="email"
						onChange={(e) => setEmail(e.target.value)}
					/>

					<p
						className="p-password"
						style={{
							width: "325px",
							textAlign: "center",
							marginBottom: "30px",
						}}
					>
						Enviaremos um link para este e-mail se corresponder a
						uma conta no Sistema Exatta.
					</p>
					<SendEmailForgetPassword
						text={"Enviar"}
						user_email={email}
					/>
				</div>
			</div>
		</div>
	);
}
