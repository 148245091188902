import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import AuthService from "../../../../services/auth";

import { useGet } from "../../../../reducers/useGet";

import PanelHeader from "../../panel-header";

import TensiometerChartPanel from "../../panel/tensiometer-chart-panel";
import NoDeviceConnectedMessage from "../../messages-panel/no-device-connected-message";
import ErrorMessage from "../../messages-panel/error-message";

export default function SoilData({ gateway }) {
	const { access_token } = AuthService.getCurrentUser();
	const [colList, setColList] = useState([]);
	const [coletor, setColetor] = useState(null);
	const [arrayEquipamentos, setArrayEquipamentos] = useState([]);

	let getData = useGet(
		`sensor_consulta?sensor_concentrador=${gateway.id}`,
		access_token
	);
	let getDL = useGet(
		`coletor_consulta?coletor_gateway=${gateway.id}`,
		access_token
	);

	useEffect(() => {
		if (
			!getData.state.loading &&
			!getData.state.error &&
			getData.state.data.length > 0 &&
			!getDL.state.loading &&
			!getDL.state.error &&
			getDL.state.data.length > 0
		) {
			const equipamentosAgrupados = [];
			const dls = []; // Lista com os coletores que possuem tensiômetros conectados

			getDL.state.data.forEach((dl) => {
				const sensoresCorrespondentes = getData.state.data.filter(
					(s) => s.coletor === dl.id && s.tag.includes("cm")
				);

				if (sensoresCorrespondentes.length > 0) {
					equipamentosAgrupados[dl.tag] = sensoresCorrespondentes;
					dls.push({ id: dl.id, nome: dl.tag });
				}
			});

			setColList(dls);
			setArrayEquipamentos(equipamentosAgrupados);
		}
	}, [
		getData.state.loading,
		getData.state.error,
		getData.state.data,
		getDL.state.loading,
		getDL.state.error,
		getDL.state.data,
	]);

	return (
		<div className="dropdown-button-line">
			{getData.state.loading && getDL.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : getData.state.error || getDL.state.error ? (
				<ErrorMessage />
			) : colList.length === 0 ? (
				<NoDeviceConnectedMessage />
			) : (
				<div style={{ paddingBottom: "10px" }}>
					<PanelHeader
						componentsList={colList}
						chosenComponent={coletor}
						setChosenComponent={setColetor}
						titleHeader={"Dados referentes à Estação"}
						imageType={"management-zones"}
					/>
					{coletor ? (
						<div className="show-data-container">
							<TensiometerChartPanel
								sensorsList={arrayEquipamentos[coletor.nome]}
							/>
						</div>
					) : null}
				</div>
			)}
		</div>
	);
}
