import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import downloadCSV from '../../../../../scripts/export-script';
import { tipoMsgMap } from '../../../../admin/items';
import { paginationComponentOptions } from '../../../../data-table-custom/dataTableCustom';
import ExportButton from '../../../../data-table-custom/export-button';

import moment from 'moment';

export default function DataTablePanel({ data, type }) {
	const [tableData, setTableData] = useState([]);
	const [columns, setColumns] = useState([]);

	useEffect(() => {
		if (data && type) {
			let convertData;
			let columnsConfig;

			switch (type) {
				case 'Manual':
					convertData = data.map((d) => ({
						uidCol: d.uidCol,
						tipoMsg: d.tipoMsg,
						timestamp: d.timestamp,
						opFlux: d.ValorSns_1,
						levelConfig: d.ValorSns_2,
						levelStatus: d.ValorSns_3,
					}));
					columnsConfig = [
						{
							name: 'Data',
							selector: (row) => moment.unix(row.timestamp).format('YYYY-MM-DD HH:mm'),
							sortable: true,
							width: '15%',
						},
						{
							name: 'ID Coletor',
							selector: (row) => (row.uidCol !== null ? row.uidCol : null),
							omit: convertData.every((item) => !item.uidCol),
						},
						{
							name: 'Modo de Operação',
							selector: (row) =>
								row.tipoMsg !== null ? tipoMsgMap[parseInt(row.tipoMsg)] || 'Desconhecido' : null,
							omit: convertData.every((item) => item.tipoMsg === null),
						},
						{
							name: 'Fluxo de Operação (%)',
							selector: (row) => (row.opFlux !== null ? row.opFlux : null),
							omit: convertData.every((item) => !item.opFlux),
						},
						{
							name: 'Configuração Sensor de Nível',
							selector: (row) => {
								if (row.levelConfig !== null) {
									switch (parseInt(row.levelConfig)) {
										case 0:
											return 'Normal Fechado';
										case 1:
											return 'Normal Aberto';
										case 2:
											return 'Desabilitado';
										default:
											return null;
									}
								}
								return null;
							},
							omit: convertData.every((item) => !item.levelConfig),
						},
						{
							name: 'Status Sensor de Nível',
							selector: (row) => {
								if (row.levelStatus !== null) {
									switch (parseInt(row.levelStatus)) {
										case 0:
											return 'Acionado';
										case 1:
											return 'Desacionado';
										default:
											return null;
									}
								}
								return null;
							},
							omit: convertData.every((item) => !item.levelStatus),
						},
					];
					break;
				case 'Divisor':
					convertData = data.map((d) => ({
						uidCol: d.uidCol,
						tipoMsg: d.tipoMsg,
						timestamp: d.timestamp,
						divisionFactor: d.ValorSns_1,
						levelConfig: d.ValorSns_2,
						levelStatus: d.ValorSns_3,
					}));
					columnsConfig = [
						{
							name: 'Data',
							selector: (row) => moment.unix(row.timestamp).format('YYYY-MM-DD HH:mm'),
							sortable: true,
							width: '15%',
						},
						{
							name: 'ID Coletor',
							selector: (row) => (row.uidCol !== null ? row.uidCol : null),
							omit: convertData.every((item) => !item.uidCol),
						},
						{
							name: 'Modo de Operação',
							selector: (row) =>
								row.tipoMsg !== null ? tipoMsgMap[parseInt(row.tipoMsg)] || 'Desconhecido' : null,
							omit: convertData.every((item) => item.tipoMsg === null),
						},
						{
							name: 'Fator de Divisão',
							selector: (row) => (row.divisionFactor !== null ? row.divisionFactor : null),
							omit: convertData.every((item) => !item.divisionFactor),
						},
						{
							name: 'Configuração Sensor de Nível',
							selector: (row) => {
								if (row.levelConfig !== null) {
									switch (parseInt(row.levelConfig)) {
										case 0:
											return 'Normal Fechado';
										case 1:
											return 'Normal Aberto';
										case 2:
											return 'Desabilitado';
										default:
											return null;
									}
								}
								return null;
							},
							omit: convertData.every((item) => !item.levelConfig),
						},
						{
							name: 'Status Sensor de Nível',
							selector: (row) => {
								if (row.levelStatus !== null) {
									switch (parseInt(row.levelStatus)) {
										case 0:
											return 'Acionado';
										case 1:
											return 'Desacionado';
										default:
											return null;
									}
								}
								return null;
							},
							omit: convertData.every((item) => !item.levelStatus),
						},
					];
					break;
				case 'Multiplicador':
					convertData = data.map((d) => ({
						uidCol: d.uidCol,
						tipoMsg: d.tipoMsg,
						timestamp: d.timestamp,
						multiplicationFactor: d.ValorSns_1,
						levelConfig: d.ValorSns_2,
						levelStatus: d.ValorSns_3,
					}));
					columnsConfig = [
						{
							name: 'Data',
							selector: (row) => moment.unix(row.timestamp).format('YYYY-MM-DD HH:mm'),
							sortable: true,
							width: '15%',
						},
						{
							name: 'ID Coletor',
							selector: (row) => (row.uidCol !== null ? row.uidCol : null),
							omit: convertData.every((item) => !item.uidCol),
						},
						{
							name: 'Modo de Operação',
							selector: (row) =>
								row.tipoMsg !== null ? tipoMsgMap[parseInt(row.tipoMsg)] || 'Desconhecido' : null,
							omit: convertData.every((item) => item.tipoMsg === null),
						},
						{
							name: 'Fator de Multiplicação',
							selector: (row) =>
								row.multiplicationFactor !== null ? row.multiplicationFactor : null,
							omit: convertData.every((item) => !item.multiplicationFactor),
						},
						{
							name: 'Configuração Sensor de Nível',
							selector: (row) => {
								if (row.levelConfig !== null) {
									switch (parseInt(row.levelConfig)) {
										case 0:
											return 'Normal Fechado';
										case 1:
											return 'Normal Aberto';
										case 2:
											return 'Desabilitado';
										default:
											return null;
									}
								}
								return null;
							},
							omit: convertData.every((item) => !item.levelConfig),
						},
						{
							name: 'Status Sensor de Nível',
							selector: (row) => {
								if (row.levelStatus !== null) {
									switch (parseInt(row.levelStatus)) {
										case 0:
											return 'Acionado';
										case 1:
											return 'Desacionado';
										default:
											return null;
									}
								}
								return null;
							},
							omit: convertData.every((item) => !item.levelStatus),
						},
					];
					break;
				case 'Ph':
					convertData = data.map((d) => ({
						uidCol: d.uidCol,
						tipoMsg: d.tipoMsg,
						timestamp: d.timestamp,
						product: d.ValorSns_1,
						currentPh: d.ValorSns_2,
						expectedPh: d.ValorSns_3,
						alarmPhMax: d.ValorSns_4,
						alarmPhMin: d.ValorSns_5,
						temperature: d.ValorSns_6,
					}));
					columnsConfig = [
						{
							name: 'Data',
							selector: (row) => moment.unix(row.timestamp).format('YYYY-MM-DD HH:mm'),
							sortable: true,
							width: '15%',
						},
						{
							name: 'ID Coletor',
							selector: (row) => (row.uidCol !== null ? row.uidCol : null),
							omit: convertData.every((item) => !item.uidCol),
						},
						{
							name: 'Modo de Operação',
							selector: (row) =>
								row.tipoMsg !== null ? tipoMsgMap[parseInt(row.tipoMsg)] || 'Desconhecido' : null,
							omit: convertData.every((item) => item.tipoMsg === null),
						},
						{
							name: 'Produto',
							selector: (row) => {
								if (row.product !== null) {
									switch (parseInt(row.product)) {
										case 0:
											return 'Alcalino';
										case 1:
											return 'Acído';
										default:
											return null;
									}
								}
								return null;
							},
							omit: convertData.every((item) => !item.product),
						},
						{
							name: 'pH Atual',
							selector: (row) =>
								row.currentPh !== null ? parseFloat(row.currentPh).toFixed(1) : null,
							omit: convertData.every((item) => !item.currentPh),
						},
						{
							name: 'pH Esperado',
							selector: (row) => (row.expectedPh !== null ? row.expectedPh : null),
							omit: convertData.every((item) => !item.expectedPh),
						},
						{
							name: 'pH Máximo Alarme',
							selector: (row) =>
								row.alarmPhMax !== null ? parseFloat(row.alarmPhMax).toFixed(1) : null,
							omit: convertData.every((item) => !item.alarmPhMax),
						},
						{
							name: 'pH Máximo Alarme',
							selector: (row) => (row.alarmPhMin !== null ? row.alarmPhMin : null),
							omit: convertData.every((item) => !item.alarmPhMin),
						},
						{
							name: 'Temperatura em Cº',
							selector: (row) => (row.temperature !== null ? row.temperature : null),
							omit: convertData.every((item) => !item.temperature),
						},
					];
					break;
				case 'Orp':
					convertData = data.map((d) => ({
						uidCol: d.uidCol,
						tipoMsg: d.tipoMsg,
						timestamp: d.timestamp,
						product: d.ValorSns_1,
						currentOrp: d.ValorSns_2,
						expectedOrp: d.ValorSns_3,
						alarmOrpMax: d.ValorSns_4,
						alarmOrpMin: d.ValorSns_5,
					}));
					columnsConfig = [
						{
							name: 'Data',
							selector: (row) => moment.unix(row.timestamp).format('YYYY-MM-DD HH:mm'),
							sortable: true,
							width: '15%',
						},
						{
							name: 'ID Coletor',
							selector: (row) => (row.uidCol !== null ? row.uidCol : null),
							omit: convertData.every((item) => !item.uidCol),
						},
						{
							name: 'Modo de Operação',
							selector: (row) =>
								row.tipoMsg !== null ? tipoMsgMap[parseInt(row.tipoMsg)] || 'Desconhecido' : null,
							omit: convertData.every((item) => item.tipoMsg === null),
						},
						{
							name: 'Produto',
							selector: (row) => {
								if (row.product !== null) {
									switch (parseInt(row.product)) {
										case 0:
											return 'Redutor';
										case 1:
											return 'Oxidante';
										default:
											return null;
									}
								}
								return null;
							},
							omit: convertData.every((item) => !item.product),
						},
						{
							name: 'ORP Atual',
							selector: (row) =>
								row.currentOrp !== null ? parseFloat(row.currentOrp).toFixed(1) : null,
							omit: convertData.every((item) => !item.currentOrp),
						},
						{
							name: 'ORP Esperado',
							selector: (row) => (row.expectedOrp !== null ? row.expectedOrp : null),
							omit: convertData.every((item) => !item.expectedOrp),
						},
						{
							name: 'ORP Máximo Alarme',
							selector: (row) =>
								row.alarmOrpMax !== null ? parseFloat(row.alarmOrpMax).toFixed(1) : null,
							omit: convertData.every((item) => !item.alarmOrpMax),
						},
						{
							name: 'ORP Máximo Alarme',
							selector: (row) => (row.alarmOrpMin !== null ? row.alarmOrpMin : null),
							omit: convertData.every((item) => !item.alarmOrpMin),
						},
					];
					break;
				case 'Analog':
					convertData = data.map((d) => ({
						uidCol: d.uidCol,
						tipoMsg: d.tipoMsg,
						timestamp: d.timestamp,
						unit: d.ValorSns_1,
						maxSignal: d.ValorSns_2,
						minSignal: d.ValorSns_3,
						dosingFluxMax: d.ValorSns_4,
						dosingFluxMin: d.ValorSns_5,
						levelConfig: d.ValorSns_6,
						levelStatus: d.ValorSns_7,
					}));
					columnsConfig = [
						{
							name: 'Data',
							selector: (row) => moment.unix(row.timestamp).format('YYYY-MM-DD HH:mm'),
							sortable: true,
							width: '15%',
						},
						{
							name: 'ID Coletor',
							selector: (row) => (row.uidCol !== null ? row.uidCol : null),
							omit: convertData.every((item) => !item.uidCol),
						},
						{
							name: 'Modo de Operação',
							selector: (row) =>
								row.tipoMsg !== null ? tipoMsgMap[parseInt(row.tipoMsg)] || 'Desconhecido' : null,
							omit: convertData.every((item) => item.tipoMsg === null),
						},
						{
							name: 'Unidade',
							selector: (row) => (parseInt(row.unit) === 1 ? 'Corrente' : 'Tensão'),
							sortable: true,
							omit: convertData.every((item) => !item.unit),
						},
						{
							name: 'Sinal Máximo',
							selector: (row) =>
								row.maxSignal !== null
									? `${parseFloat(row.maxSignal).toFixed(1)} ${
											parseInt(row.unit) === 0 ? 'V' : 'mA'
									  }`
									: null,
							omit: convertData.every((item) => !item.maxSignal),
						},
						{
							name: 'Sinal Mínimo',
							selector: (row) =>
								row.minSignal !== null
									? `${parseFloat(row.minSignal).toFixed(1)} ${
											parseInt(row.unit) === 0 ? 'V' : 'mA'
									  }`
									: null,
							omit: convertData.every((item) => !item.minSignal),
						},
						{
							name: 'Fluxo de Dosagem Máximo',
							selector: (row) =>
								row.dosingFluxMax !== null ? parseFloat(row.dosingFluxMax).toFixed(1) : null,
							omit: convertData.every((item) => !item.dosingFluxMax),
						},
						{
							name: 'Fluxo de Dosagem Mínimo',
							selector: (row) =>
								row.dosingFluxMin !== null ? parseFloat(row.dosingFluxMin).toFixed(1) : null,
							omit: convertData.every((item) => !item.dosingFluxMin),
						},
						{
							name: 'Configuração Sensor de Nível',
							selector: (row) => {
								if (row.levelConfig !== null) {
									switch (parseInt(row.levelConfig)) {
										case 0:
											return 'Normal Fechado';
										case 1:
											return 'Normal Aberto';
										case 2:
											return 'Desabilitado';
										default:
											return null;
									}
								}
								return null;
							},
							omit: convertData.every((item) => !item.levelConfig),
						},
						{
							name: 'Status Sensor de Nível',
							selector: (row) => {
								if (row.levelStatus !== null) {
									switch (parseInt(row.levelStatus)) {
										case 0:
											return 'Acionado';
										case 1:
											return 'Desacionado';
										default:
											return null;
									}
								}
								return null;
							},
							omit: convertData.every((item) => !item.levelStatus),
						},
					];
					break;
				default:
					convertData = data;
					columnsConfig = [];
			}
			setTableData(convertData);
			setColumns(columnsConfig);
		}
	}, [data, type]);

	const Export = ({ onExport }) => <ExportButton onExport={onExport} chartPanel={false} />;

	const actionsMemo = useMemo(
		() => <Export onExport={() => downloadCSV(data, 'dados-horarios')} />,
		[data]
	);

	return (
		<div className="big-panel-container" style={{ margin: '10px 50px 10px 50px' }}>
			<div className="panel-title-container">
				<em className="panel-title">Compilado dos Dados por Hora</em>
			</div>

			<div style={{ margin: '10px' }}>
				{tableData.length > 0 ? (
					<div style={{ margin: '0px 20px 0px 20px' }}>
						<DataTable
							columns={columns}
							data={tableData}
							noDataComponent="Nenhum dado encontrado."
							fixedHeader
							pagination
							paginationComponentOptions={paginationComponentOptions}
							striped={true}
							actions={actionsMemo}
						/>
					</div>
				) : (
					<div>Nenhum dado encontrado.</div>
				)}
			</div>
		</div>
	);
}
