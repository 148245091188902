import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/auth";
import { useGet } from "../../../reducers/useGet";

import DataTable from "react-data-table-component";
import AddButton from "../../data-table-custom/add-button";
import SearchBar from "../../data-table-custom/search-bar";
import ExportButton from "../../../components/data-table-custom/export-button";
import downloadCSV from "../../../scripts/export-script";
import { paginationComponentOptions } from "../../data-table-custom/dataTableCustom";
import ErrorMessage from "../../dashboard/messages-panel/error-message";

export default function SensorList() {
	const user = AuthService.getCurrentUser();

	let api_request_org, api_request_gtw, api_request_dl, api_request_sensor;

	switch (user.nivel) {
		case 2:
			api_request_org = `organizacao_consulta?organi_representante=${user.organizacao}`;
			api_request_gtw = `gateway_consulta?gateway_representante=${user.organizacao}`;
			api_request_dl = `coletor_consulta?coletor_representante=${user.organizacao}`;
			api_request_sensor = `sensor_consulta?sensor_representante=${user.organizacao}`;
			break;

		case 3:
			api_request_org = "organizacao_api";
			api_request_gtw = "gateway_api";
			api_request_dl = "coletor_api";
			api_request_sensor = "sensor_api";
			break;

		default:
			api_request_org = `organizacao_consulta?organi_id=${user.organizacao}`;
			api_request_gtw = `gateway_consulta?gateway_organi=${user.organizacao}`;
			api_request_dl = `coletor_consulta?coletor_organizacao=${user.organizacao}`;
			api_request_sensor = `sensor_consulta?sensor_organizacao=${user.organizacao}`;
			break;
	}

	let sensorsList = useGet(api_request_sensor, user.access_token);
	let organizationsList = useGet(api_request_org, user.access_token);
	let gatewaysList = useGet(api_request_gtw, user.access_token);
	let dataloggersList = useGet(api_request_dl, user.access_token);
	let deviceModelsList = useGet("modelo_api", user.access_token);
	let sensorTypeList = useGet("tipo_sensor_api", user.access_token);

	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data); // To be used in the SearchBar
	const [organizationsNameList, setOrganizationsNameList] = useState(null);
	const [gatewaysNameList, setGatewaysNameList] = useState(null);
	const [dataloggersNameList, setdataloggersNameList] = useState(null);
	const [deviceModelsNameList, setdeviceModelsNameList] = useState(null);
	const [sensorTypeNameList, setSensorTypeNameList] = useState(null);

	useEffect(() => {
		if (
			!sensorsList.state.loading &&
			!sensorsList.state.error &&
			!organizationsList.state.loading &&
			!organizationsList.state.error &&
			!gatewaysList.state.loading &&
			!gatewaysList.state.error &&
			!dataloggersList.state.loading &&
			!dataloggersList.state.error &&
			!deviceModelsList.state.loading &&
			!deviceModelsList.state.error &&
			!sensorTypeList.state.loading &&
			!sensorTypeList.state.error
		) {
			setData(sensorsList.state.data);
			setRecords(data);

			let gtw_names_id = gatewaysList.state.data.map((d) => {
				return { id: d.id, nome: d.tag };
			});

			let dl_names_id = dataloggersList.state.data.map((d) => {
				return { id: d.id, nome: d.tag };
			});

			setOrganizationsNameList(organizationsList.state.data);
			setGatewaysNameList(gtw_names_id);
			setdataloggersNameList(dl_names_id);
			setdeviceModelsNameList(deviceModelsList.state.data);
			setSensorTypeNameList(sensorTypeList.state.data);
		}
	}, [
		data,
		sensorsList.state.loading,
		sensorsList.state.data,
		sensorsList.state.error,
		organizationsList.state.loading,
		organizationsList.state.error,
		organizationsList.state.data,
		gatewaysList.state.loading,
		gatewaysList.state.data,
		gatewaysList.state.error,
		dataloggersList.state.loading,
		dataloggersList.state.data,
		dataloggersList.state.error,
		deviceModelsList.state.loading,
		deviceModelsList.state.data,
		deviceModelsList.state.error,
		sensorTypeList.state.loading,
		sensorTypeList.state.data,
		sensorTypeList.state.error,
	]);

	const columns = [
		{ name: "id", selector: (row) => row.id, sortable: true },
		{ name: "ID", selector: (row) => row.id_dispositivo },
		{ name: "Nome", selector: (row) => row.tag, sortable: true },
		{
			name: "Número de Série",
			selector: (row) => row.serie,
			sortable: true,
		},
		{
			name: "Organização",
			selector: (row) => {
				let org = organizationsList.state.data.find(
					(el) => el.id === row.organi_sensor_id
				);

				if (org === undefined) return "";
				else return org.nome;
			},
		},
		{
			name: "Status",
			selector: (row) => (row.status === "on" ? "Ativo" : "Inativo"),
		},
	];

	const openSensorDetail = (sensorData) => {
		Navigate(`/sensors/${sensorData.id}`, {
			state: {
				sensorData,
				organizationsNameList,
				gatewaysNameList,
				dataloggersNameList,
				deviceModelsNameList,
				sensorTypeNameList,
			},
		});
	};

	const Export = ({ onExport }) => (
		<ExportButton onExport={onExport} chartPanel={false} />
	);

	return (
		<div className="page">
			{sensorsList.state.loading &&
			organizationsList.state.loading &&
			gatewaysList.state.loading &&
			dataloggersList.state.loading &&
			deviceModelsList.state.loading &&
			sensorTypeList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : sensorsList.state.loading ||
			  organizationsList.state.loading ||
			  gatewaysList.state.loading ||
			  dataloggersList.state.loading ||
			  deviceModelsList.state.loading ||
			  sensorTypeList.state.loading ? (
				<ErrorMessage />
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar
							records={records}
							setRecords={setRecords}
							data={data}
							keyword={"ID"}
						/>
						<Export
							onExport={() => downloadCSV(data, "sensores")}
						/>
						{user.nivel === 3 ? (
							<AddButton
								goTo={"/sensors/add-new-sensor"}
								list={{
									organizationsNameList,
									gatewaysNameList,
									dataloggersNameList,
									deviceModelsNameList,
									sensorTypeNameList,
								}}
							/>
						) : null}
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhum Sensor encontrado."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(sensorData) =>
							user.nivel === 3
								? openSensorDetail(sensorData)
								: null
						}
					/>
				</div>
			)}
		</div>
	);
}
