import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { paginationComponentOptions } from '../../../components/data-table-custom/dataTableCustom';
import SearchBar from '../../../components/data-table-custom/search-bar';

export default function PubEspecList() {
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data);

	// Seu JSON de exemplo
	const jsonData = [
		{
			atributo1: 'valor1',
			atributo2: 'valor2',
			atributo3: 'valor3',
			atributo4: 'valor4',
			atributo5: 'valor5',
			atributo6: 'valor6',
			atributo7: 'valor7',
			atributo8: 'valor8',
			atributo9: 'valor9',
			atributo10: 'valor10',
			atributo11: 'valor11',
			atributo12: 'valor12',
			atributo13: 'valor13',
			atributo14: 'valor14',
			atributo15: 'valor15',
			atributo16: 'valor16',
			atributo17: 'valor17',
			atributo18: 'valor18',
			atributo19: 'valor19',
			atributo20: 'valor20',
			atributo21: 'valor21',
			atributo22: 'valor22',
			atributo23: 'valor23',
			atributo24: 'valor24',
			atributo25: 'valor25',
			atributo26: 'valor26',
			atributo27: 'valor27',
			atributo28: 'valor28',
			atributo29: 'valor29',
			atributo30: 'valor30',
			atributo31: 'valor31',
			atributo32: 'valor32',
			atributo33: 'valor33',
			atributo34: 'valor34',
			atributo35: 'valor35',
			atributo36: 'valor36',
			atributo37: 'valor37',
			atributo38: 'valor38',
			atributo39: 'valor39',
			atributo40: 'valor40',
			atributo41: 'valor41',
		},
		{
			atributo1: 'valor1',
			atributo2: 'valor2',
			atributo3: 'valor3',
			atributo4: 'valor4',
			atributo5: 'valor5',
			atributo6: 'valor6',
			atributo7: 'valor7',
			atributo8: 'valor8',
			atributo9: 'valor9',
			atributo10: 'valor10',
			atributo11: 'valor11',
			atributo12: 'valor12',
			atributo13: 'valor13',
			atributo14: 'valor14',
			atributo15: 'valor15',
			atributo16: 'valor16',
			atributo17: 'valor17',
			atributo18: 'valor18',
			atributo19: 'valor19',
			atributo20: 'valor20',
			atributo21: 'valor21',
			atributo22: 'valor22',
			atributo23: 'valor23',
			atributo24: 'valor24',
			atributo25: 'valor25',
			atributo26: 'valor26',
			atributo27: 'valor27',
			atributo28: 'valor28',
			atributo29: 'valor29',
			atributo30: 'valor30',
			atributo31: 'valor31',
			atributo32: 'valor32',
			atributo33: 'valor33',
			atributo34: 'valor34',
			atributo35: 'valor35',
			atributo36: 'valor36',
			atributo37: 'valor37',
			atributo38: 'valor38',
			atributo39: 'valor39',
			atributo40: 'valor40',
			atributo41: 'valor41',
		},
	];

	useEffect(() => {
		setData(jsonData);

		setRecords(data);
	}, [data]);

	// Função para obter todas as chaves únicas do JSON
	function getAllKeys(jsonData) {
		let keys = new Set();
		jsonData.forEach((obj) => {
			Object.keys(obj).forEach((key) => keys.add(key));
		});
		return Array.from(keys);
	}

	// Função para criar colunas dinâmicas com base nas chaves do JSON
	function createDynamicColumns(jsonData) {
		const keys = getAllKeys(jsonData);

		const columns = keys.map((key) => ({
			name: key.charAt(0).toUpperCase() + key.slice(1), // Transforma a primeira letra em maiúscula
			selector: (row) => row[key],
			sortable: true,
		}));

		return columns;
	}

	// Criar as colunas dinâmicas
	const dynamicColumns = createDynamicColumns(jsonData);

	return (
		<div className="page">
			<SearchBar records={records} setRecords={setRecords} data={data} keyword={'TAG'} />
			<DataTable
				columns={dynamicColumns}
				data={records}
				noDataComponent="Nenhum Concentrador encontrado."
				fixedHeader
				pagination
				paginationComponentOptions={paginationComponentOptions}
				pointerOnHover={true}
				striped={true}
			/>
		</div>
	);
}
