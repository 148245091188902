import '../../dosing-styles.css';
import { LinearGauge } from '../charts';

export default function PhPanel({ data, type }) {
	return (
		<div className="little-panel-container" style={{ maxHeight: 400 }}>
			{data ? (
				<>
					<div className="panel-title-container">
						<em className="panel-title">pH Atual</em>
					</div>

					<LinearGauge value={parseFloat(data[0].ValorSns_2)} />
					<div className="panel-title-container">
						<em className="panel-title">pH Esperado</em>
					</div>
					<LinearGauge value={parseFloat(data[0].ValorSns_3)} />
				</>
			) : (
				<div></div>
			)}
		</div>
	);
}
