import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/auth";

import { useGet } from "../../../reducers/useGet";

import DataTable from "react-data-table-component";
import AddButton from "../../../components/data-table-custom/add-button";
import SearchBar from "../../../components/data-table-custom/search-bar";
import { paginationComponentOptions } from "../../../components/data-table-custom/dataTableCustom";
import ErrorMessage from "../../dashboard/messages-panel/error-message";

export default function SensorTypeList() {
	let { access_token } = AuthService.getCurrentUser();
	let sensorTypeList = useGet("tipo_sensor_api", access_token);

	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data); // To be used in the SearchBar

	useEffect(() => {
		if (!sensorTypeList.state.loading && !sensorTypeList.state.error) {
			setData(sensorTypeList.state.data);
			setRecords(data);
		}
	}, [
		sensorTypeList.state.loading,
		sensorTypeList.state.error,
		sensorTypeList.state.data,
		data,
	]);

	const columns = [
		{
			name: "ID",
			selector: (row) => row.id,
			sortable: true,
		},
		{ name: "Nome", selector: (row) => row.nome, sortable: true },
	];

	const openSensorTypeDetail = (sensorTypeData) => {
		Navigate(`/sensor-type/${sensorTypeData.id}`, {
			state: {
				sensorTypeData,
			},
		});
	};

	return (
		<div className="page">
			{sensorTypeList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : sensorTypeList.state.error ? (
				<ErrorMessage />
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar
							records={records}
							setRecords={setRecords}
							data={data}
							keyword={"NAME"}
						/>
						<AddButton goTo={"/sensor-type/add-new-sensor-type"} />
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhum Tipo de Sensor encontrado."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(sensorTypeData) =>
							openSensorTypeDetail(sensorTypeData)
						}
					/>
				</div>
			)}
		</div>
	);
}
