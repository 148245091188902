import React from "react";

import {
	MdOutlineToday,
	MdOutlineKeyboardArrowDown,
	MdOutlineKeyboardArrowUp,
	MdOutlineBalance,
} from "react-icons/md";
import { GiPlantWatering } from "react-icons/gi";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { BiLineChart } from "react-icons/bi";
import WellBeingIcon from "../../../../assets/icons/wellbeing-icon.png";
import SiloIcon from "../../../../assets/icons/silo-icon.png";
import WatterLoggingIcon from "../../../../assets/icons/waterlogging-icon.png";
import PitIcon from "../../../../assets/icons/pit-icon.png";
import ShedIcon from "../../../../assets/icons/shed-icon.png";
import WaterTankIcon from "../../../../assets/icons/water-tank-icon.png";

export default function DashboardButtons({
	buttonType,
	buttonNumber,
	setButtonNumber,
	buttonTitle,
	reference,
}) {
	let Icon = () => {
		switch (buttonType) {
			case "horario":
				return (
					<RxCounterClockwiseClock
						size={"20px"}
						color={"rgb(7, 39, 26)"}
						style={{ marginLeft: "20px" }}
					/>
				);
			case "diario":
				return (
					<MdOutlineToday
						size={"20px"}
						color={"rgb(7, 39, 26)"}
						style={{ marginLeft: "20px" }}
					/>
				);
			case "balanco-hidrico":
				return (
					<MdOutlineBalance
						size={"20px"}
						color={"rgb(7, 39, 26)"}
						style={{ marginLeft: "20px" }}
					/>
				);
			case "pulverizacao":
				return (
					<GiPlantWatering
						size={"20px"}
						color={"rgb(7, 39, 26)"}
						style={{ marginLeft: "20px" }}
					/>
				);
			case "sensores-solo":
				return (
					<BiLineChart
						size={"20px"}
						color={"rgb(7, 39, 26)"}
						style={{ marginLeft: "20px" }}
					/>
				);
			case "wellbeing":
				return (
					<img
						src={WellBeingIcon}
						height={"25px"}
						alt=""
						style={{ marginLeft: "20px" }}
					/>
				);
			case "waterlogging":
				return (
					<img
						src={WatterLoggingIcon}
						height={"25px"}
						alt=""
						style={{ marginLeft: "20px" }}
					/>
				);
			case "nivel-silo":
				return (
					<img
						src={SiloIcon}
						height={"25px"}
						alt=""
						style={{ marginLeft: "20px" }}
					/>
				);
			case "pocos":
				return (
					<img
						src={PitIcon}
						height={"30px"}
						alt=""
						style={{ marginLeft: "20px" }}
					/>
				);
			case "galpao":
				return (
					<img
						src={ShedIcon}
						height={"30px"}
						alt=""
						style={{ marginLeft: "20px" }}
					/>
				);

			case "reservatorio":
				return (
					<img
						src={WaterTankIcon}
						height={"30px"}
						alt=""
						style={{ marginLeft: "20px" }}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<button
			className="dropdown-button-style"
			onClick={() => setButtonNumber(!buttonNumber)}
			disabled={!reference ? true : false}
		>
			<Icon />
			<h3 className="button-title">{buttonTitle}</h3>
			<div className="dropdown-button-arrow">
				{buttonNumber ? (
					<MdOutlineKeyboardArrowUp size={"20px"} color={"black"} />
				) : (
					<MdOutlineKeyboardArrowDown size={"20px"} color={"black"} />
				)}
			</div>
		</button>
	);
}
