import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import ReactLoading from 'react-loading';
import { useGet } from '../../../reducers/useGet';
import downloadCSV from '../../../scripts/export-script';
import AuthService from '../../../services/auth';
import ErrorMessage from '../../dashboard/messages-panel/error-message';
import { paginationComponentOptions } from '../../data-table-custom/dataTableCustom';
import ExportButton from '../../data-table-custom/export-button';
import PhDataDosingPanel from './components/panels/ph-data-panel';
import WaterTemperatureDataDosingPanel from './components/panels/water-temperature-data-panel';

export default function DosingList() {
	let { access_token } = AuthService.getCurrentUser();

	const [data, setData] = useState([]);
	const [dataChart, setDataChart] = useState(null);
	const [records, setRecords] = useState(data);

	let dosingList = useGet('dosador_api', access_token);

	useEffect(() => {
		if (!dosingList.state.loading && !dosingList.state.error) {
			setData(dosingList.state.data);
			setRecords(data);
		}
	}, [dosingList.state.loading, dosingList.state.data, dosingList.state.error, data]);

	const columns = [
		{ name: 'ID', selector: (row) => row.id, sortable: true },
		{
			name: 'Res Level Max',
			selector: (row) => (row.res_level_max === 'on' ? 'Ativo' : 'Inativo'),
			sortable: true,
		},
		{
			name: 'Res Level Min',
			selector: (row) => (row.res_level_min === 'on' ? 'Ativo' : 'Inativo'),
			sortable: true,
		},
		{
			name: 'Valor de pH',
			selector: (row) => row.ph_value,
			sortable: true,
		},
		{
			name: 'ORP',
			selector: (row) => row.orp,
			sortable: true,
		},
		{
			name: 'Temperatura da água',
			selector: (row) => row.water_temp,
			sortable: true,
		},
		{
			name: 'Configuração remota do PH',
			selector: (row) => row.ph_config,
			sortable: true,
		},
		{
			name: 'Configuração remota do ORP',
			selector: (row) => row.orp_config,
			sortable: true,
		},
		{
			name: 'Contador de pulsos do hidrômetro',
			selector: (row) => row.pulse_counter,
			sortable: true,
		},
		{
			name: 'Data',
			selector: (row) => moment.unix(row.timestamp).format('YYYY-MM-DD HH:mm'),
			sortable: true,
		},
	];

	const Export = ({ onExport }) => <ExportButton onExport={onExport} chartPanel={false} />;

	return (
		<div className="page">
			{dosingList.state.loading ? (
				<div className="loading-container">
					<ReactLoading type="bubbles" color="var(--main-color)" width="50px" height="50px" />
				</div>
			) : dosingList.state.error ? (
				<ErrorMessage />
			) : (
				<>
					<div className="show-data-container">
						<WaterTemperatureDataDosingPanel waterChartData={dosingList} />
						<PhDataDosingPanel
							currentPhValue={dosingList.state.data[0].ph_value}
							expectedPhValue={dosingList.state.data[0].ph_config}
						/>
					</div>
					<hr />
					<div className="table-container">
						<div className="buttons-container">
							<Export onExport={() => downloadCSV(data, 'Dosagem')} />
						</div>
						<DataTable
							columns={columns}
							data={records}
							noDataComponent="Nenhum dado referente a dosagem encontrado."
							fixedHeader
							pagination
							paginationComponentOptions={paginationComponentOptions}
							pointerOnHover={true}
							striped={true}
							onRowClicked={(dosingData) => {
								setDataChart(dosingData);
							}}
						/>
					</div>
				</>
			)}
			{dataChart ? (
				<div className="show-data-container">
					<WaterTemperatureDataDosingPanel waterChartData={dosingList} />
				</div>
			) : (
				<div className="show-data-container"></div>
			)}
		</div>
	);
}
