import "./styles.css";

export default function SensorLevelConfig({ data }) {
  // Função para obter o texto baseado no valor de ValorSns_2
  const getSensorStatusText = (value) => {
    switch (parseInt(value)) {
      case 0:
        return "Normal Fechado";
      case 1:
        return "Normal Aberto";
      case 2:
        return "Desabilitado";
      default:
        return "Desconhecido";
    }
  };

  // Verifica se data[0] existe e tem a propriedade ValorSns_2
  const sensorStatus = data[0]
    ? getSensorStatusText(data[0].ValorSns_2)
    : "Desconhecido";

  return (
    <div className="little-panel-container">
      <div className="panel-title-container">
        <em className="panel-title">Configuração Sensor de Nível</em>
      </div>
      <div className="show-basic-data-container">
        <h3>{sensorStatus}</h3>
      </div>
    </div>
  );
}
