import '../../styles/panel-styles-old.css';
import { PhGaugueChartNeedle } from './charts/ph-gauge-chart-with-needle';

export default function PhDataDosingPanel({ currentPhValue, expectedPhValue }) {
	return (
		<div className="little-panel-container">
			{currentPhValue && expectedPhValue ? (
				<>
					<div className="panel-title-container">
						<em className="panel-title">pH Atual</em>
					</div>
					<PhGaugueChartNeedle needleValue={currentPhValue} />
					<div className="panel-title-container">
						<em className="panel-title">pH Esperado</em>
					</div>
					<PhGaugueChartNeedle needleValue={expectedPhValue} />
				</>
			) : (
				<div></div>
			)}
		</div>
	);
}
