import { GaugeChartWithNeedleOrp } from "../../components/charts";

export default function OrpPanel({ data }) {
  return (
    <div className="little-panel-container">
      {data ? (
        <>
          <div className="panel-title-container">
            <em className="panel-title">ORP Atual</em>
          </div>
          <GaugeChartWithNeedleOrp needleValue={data[0].ValorSns_2} />
          <div className="panel-title-container">
            <em className="panel-title">ORP Esperado</em>
          </div>
          <GaugeChartWithNeedleOrp needleValue={data[0].ValorSns_3} />
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}