import noDeviceConnected from "../../../../../assets/icons/no-device-connected-icon.png";
import "../message-panels-styles.css";

export default function NoDeviceConnectedMessage() {
	return (
		<div className="message-container">
			<div className="message-content-container">
				<img
					src={noDeviceConnected}
					height={"120px"}
					width={"120px"}
					alt=""
				/>
				<span className="message-text-style">
					Nenhum Dispositivo Conectado...
				</span>
			</div>
		</div>
	);
}
