import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/auth";

import { useGet } from "../../../reducers/useGet";

import DataTable from "react-data-table-component";
import AddButton from "../../data-table-custom/add-button";
import SearchBar from "../../data-table-custom/search-bar";
import ExportButton from "../../../components/data-table-custom/export-button";
import downloadCSV from "../../../scripts/export-script";
import { paginationComponentOptions } from "../../data-table-custom/dataTableCustom";
import ErrorMessage from "../../dashboard/messages-panel/error-message";

export default function DeviceModelList() {
	let { access_token } = AuthService.getCurrentUser();
	let modelsList = useGet("modelo_api", access_token);

	const Navigate = useNavigate();
	const [data, setData] = useState([]);
	const [records, setRecords] = useState(data); // To be used in the SearchBar

	useEffect(() => {
		if (!modelsList.state.loading && !modelsList.state.error) {
			setData(modelsList.state.data);
			setRecords(data);
		}
	}, [
		modelsList.state.loading,
		modelsList.state.error,
		modelsList.state.data,
		data,
	]);

	const columns = [
		{ name: "ID", selector: (row) => row.id, sortable: true },
		{ name: "Nome", selector: (row) => row.nome, sortable: true },
		{
			name: "Unidade",
			selector: (row) => row.unidade,
		},
		{
			name: "Função de Conversão",
			selector: (row) => row.conversao,
		},
	];

	const openDeviceModelDetail = (deviceModelData) => {
		Navigate(`/device-models/${deviceModelData.id}`, {
			state: {
				deviceModelData,
			},
		});
	};

	const Export = ({ onExport }) => (
		<ExportButton onExport={onExport} chartPanel={false} />
	);

	return (
		<div className="page">
			{modelsList.state.loading ? (
				<div className="loading-container">
					<ReactLoading
						type="bubbles"
						color="var(--main-color)"
						width="50px"
						height="50px"
					/>
				</div>
			) : modelsList.state.error ? (
				<ErrorMessage />
			) : (
				<div className="table-container">
					<div className="buttons-container">
						<SearchBar
							records={records}
							setRecords={setRecords}
							data={data}
							keyword={"NAME"}
						/>
						<Export
							onExport={() =>
								downloadCSV(data, "modelosSensores")
							}
						/>
						<AddButton
							goTo={"/device-models/add-new-device-model"}
						/>
					</div>
					<DataTable
						columns={columns}
						data={records}
						noDataComponent="Nenhum Modelo encontrado."
						fixedHeader
						pagination
						paginationComponentOptions={paginationComponentOptions}
						pointerOnHover={true}
						striped={true}
						onRowClicked={(deviceModelData) =>
							openDeviceModelDetail(deviceModelData)
						}
					/>
				</div>
			)}
		</div>
	);
}
