import DataTablePanel from "../../components/panels/data-table-panel/data-table-panel";

export default function AnalogMode({data}) {
  return (
    <div className="dropdown-button-line">
      <div className="show-data-container">
        <DataTablePanel data={data} type={"Analog"} />
      </div>
    </div>
  );
}
