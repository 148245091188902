import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthService from "../../services/auth";
import Navbar from "../navbar";
import Dashboard from "../dashboard";
import { DeviceTree } from "./device-tree";

import { GatewayList, GatewayEdit, GatewayCreate } from "./gateways";
import {
  DataloggerList,
  DataloggerEdit,
  DataloggerCreate,
} from "./dataloggers";
import { SensorList, SensorEdit, SensorCreate } from "./sensors";

import {
  DeviceModelList,
  DeviceModelEdit,
  DeviceModelCreate,
} from "./sensors-models";
import {
  SensorTypeList,
  SensorTypeEdit,
  SensorTypeCreate,
} from "./sensors-type";
import DeviceStatus from "./device-status";
import { UserList, UserEdit, UserCreate } from "./users";
import {
  OrganizationList,
  OrganizationEdit,
  OrganizationCreate,
} from "./organizations";

import "./admin-styles.css";
import { PubEspecList } from "./pub-espec";
import { DosingList } from "./dosing";
import Dosing from "../dosing";

function Admin() {
  const { nivel } = AuthService.getCurrentUser();

  const [showAllSidebar, setShowAllSidebar] = useState(true);
  const contentStyle = {
    marginLeft: showAllSidebar ? "90px" : "270px",
    transition: "margin 0.2s ease",
  };

  return (
    <div>
      <Navbar
        showAllSidebar={showAllSidebar}
        setShowAllSidebar={setShowAllSidebar}
      />
      <div style={contentStyle}>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} exact />
          <Route path="/device-tree" element={<DeviceTree />} />
          <Route path="/gateways" element={<GatewayList />} />
          <Route path="/dosing-old" element={<DosingList />} />
          <Route path="/dosing" element={<Dosing />} />
          <Route
            path="/gateways/:id"
            element={
              nivel === 3 ? <GatewayEdit /> : <Navigate to="/gateways" />
            }
          />
          <Route
            path="/gateways/add-new-gateway"
            element={
              nivel === 3 ? <GatewayCreate /> : <Navigate to="/gateways" />
            }
          />
          <Route path="/dataloggers" element={<DataloggerList />} />
          <Route
            path="/dataloggers/:id"
            element={
              nivel === 3 ? <DataloggerEdit /> : <Navigate to="/dataloggers" />
            }
          />
          <Route
            path="/dataloggers/add-new-datalogger"
            element={
              nivel === 3 ? (
                <DataloggerCreate />
              ) : (
                <Navigate to="/dataloggers" />
              )
            }
          />
          <Route path="/sensors" element={<SensorList />} />
          <Route
            path="/sensors/:id"
            element={nivel === 3 ? <SensorEdit /> : <Navigate to="/sensors" />}
          />
          <Route
            path="/sensors/add-new-sensor"
            element={
              nivel === 3 ? <SensorCreate /> : <Navigate to="/sensors" />
            }
          />
          <Route path="/device-status" element={<DeviceStatus />} />
          <Route
            path="/device-models"
            element={
              nivel === 3 ? <DeviceModelList /> : <Navigate to="/dosing" />
            }
          />
          <Route
            path="/device-models/:id"
            element={
              nivel === 3 ? <DeviceModelEdit /> : <Navigate to="/dosing" />
            }
          />
          <Route
            path="/device-models/add-new-device-model"
            element={
              nivel === 3 ? <DeviceModelCreate /> : <Navigate to="/dosing" />
            }
          />
          <Route
            path="/sensor-type"
            element={
              nivel === 3 ? <SensorTypeList /> : <Navigate to="/dosing" />
            }
          />
          <Route
            path="/sensor-type/:id"
            element={
              nivel === 3 ? <SensorTypeEdit /> : <Navigate to="/dosing" />
            }
          />
          <Route
            path="/sensor-type/add-new-sensor-type"
            element={
              nivel === 3 ? <SensorTypeCreate /> : <Navigate to="/dosing" />
            }
          />
          <Route
            path="/organizations"
            element={
              nivel === 3 ? <OrganizationList /> : <Navigate to="/dosing" />
            }
          />
          <Route
            path="/organizations/:id"
            element={
              nivel === 3 ? <OrganizationEdit /> : <Navigate to="/dosing" />
            }
          />
          <Route
            path="/organizations/add-new-organization"
            element={
              nivel === 3 ? (
                <OrganizationCreate />
              ) : (
                <Navigate to="/dosing" />
              )
            }
          />

          <Route
            path="/users"
            element={nivel === 3 ? <UserList /> : <Navigate to="/dosing" />}
          />

          <Route path="/users/:id" element={<UserEdit />} />
          <Route
            path="/users/add-new-user"
            element={
              nivel === 3 ? <UserCreate /> : <Navigate to="/dosing" />
            }
          />
          <Route
            path="/pub-espec"
            element={
              nivel === 3 ? <PubEspecList /> : <Navigate to="/dosing" />
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default Admin;
